import { useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { CalendarHighlightWeek } from "hdc-components/CalendarHighlightWeek";
import { MobileDateSelect } from "hdc-components/DateTimeSelect";

export default function JobClassCalendar({ value, setValue }) {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const useStyles = makeStyles({
    input: {
      display: "none",
      width: "auto",
      "& .react-date-picker__wrapper": {
        padding: "0 10px",
        borderColor: "#ccc",
        borderRadius: "4px",
      },
      "& .react-date-picker--open": {
        borderColor: "red",
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      {!matchDownMd && (
        <CalendarHighlightWeek
          label="Week of"
          orientation="portait"
          value={value}
          setValue={setValue}
          className={classes.input}
        />
      )}
      {matchDownMd && (
        <Box p={1}>
          <MobileDateSelect
            label="Week of"
            value={value}
            setValue={setValue}
            className={classes.input}
          />
        </Box>
      )}
    </>
  );
}
