import { useEffect, useState } from "react";
import API from "services/Api";
import { createTheme, Grid, Stack, useMediaQuery } from "@mui/material";
import { Typography, Skeleton, Box } from "@mui/material";

// project imports
import { ThemeProvider } from "@mui/system";
import JobClassChip from "./JobClassChip";
import { reorder } from "helpers";

export default function JobClassLegend() {
  const isSelected = sessionStorage.getItem("isSelect");
  const [jobClasses, setJobClasses] = useState();
  const [jobClassTheme, setJobClassTheme] = useState();
  const getContrastTextColor = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "#000" : "#fff";
  };
  const setLegendTheme = (jobClassArray) => {
    let theme = { palette: {} };
    jobClassArray.forEach((jobClass) => {
      theme.palette[jobClass.name] = {
        light: LightenDarkenColor(jobClass.displayColor, 20),
        dark: LightenDarkenColor(jobClass.displayColor, -20),
        main: jobClass.displayColor,
        contrastText: getContrastTextColor(jobClass.displayColor),
      };
    });
    setJobClassTheme(theme);
  };

  function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  const handleJobClasses = (data) => {
    setLegendTheme(data);
    setJobClasses(data);
  };

  useEffect(() => {
    if (isSelected !== "") {
      API.getData("jobclass").then((data) => {
        handleJobClasses(data);
      });
    }
    return () => handleJobClasses([]);
  }, [isSelected]);
  const theme = createTheme(jobClassTheme);
  const matchDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <ThemeProvider theme={theme}>
      
        <Grid container item xs lg={12} justifyContent="start" pl={2}>
          {/* <Stack direction={matchDownLg ? "row" : "column"} spacing={1}> */}
            {jobClasses &&
              jobClassTheme.palette &&
              jobClasses.map(
                (jc, index) =>
                  jc.isActive && (
                    <Grid item xs={12} align>
                    <JobClassChip
                      size="small"
                      variant="outlined"
                      key={`${jc.name}-${index}`}
                      chipcolor={jc.name}
                      customTheme={jobClassTheme}
                      jobClass={jc}
                      label={jc.name}
                    />
                    </Grid>
                  )
              )}
          {/* </Stack> */}
        </Grid>
      {!jobClasses && <Skeleton />}
    </ThemeProvider>
  );
}
