// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";
import { SimpleAuthProvider } from "contexts/SimpleAuthContext";
import DelayReasonContext from "contexts/DelayReasonContext";
import useDelayReason from "hooks/useDelayReason";
import { DisplaySettingsProvider } from "contexts/DisplaySettingsContext";
import "react-big-calendar/lib/css/react-big-calendar.css";

const App = () => {
  const {
    delayData,
    addDelayReason,
    setDelayData,
    updateDelayReason,
    deleteDelayReason,
    messasge,
    setOpendialog,
    opendialog,
    getDelayReason,
  } = useDelayReason();

  return (
    <>
      <ThemeCustomization>
        <Locales>
          <NavigationScroll>
            <SimpleAuthProvider>
                <DisplaySettingsProvider>
                  <DelayReasonContext.Provider
                    value={{
                      delayData,
                      addDelayReason,
                      setDelayData,
                      updateDelayReason,
                      deleteDelayReason,
                      messasge,
                      setOpendialog,
                      opendialog,
                      getDelayReason,
                    }}
                  >
                    <Routes />
                    <Snackbar />
                  </DelayReasonContext.Provider>
                </DisplaySettingsProvider>
            </SimpleAuthProvider>
          </NavigationScroll>
        </Locales>
      </ThemeCustomization>
    </>
  );
};

export default App;
