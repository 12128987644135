import { 
  Box,
  FormControlLabel, 
  Grid,
  Switch,
  Typography } from "@mui/material";
import { useState } from "react";

const SchedulerSettings = ({data, handleChange}) => {
  const [ settings, setSettings ] = useState(data);

  const onChange = (e) => {
    const updated = structuredClone(settings);
    updated[e.target.name] = e.target.checked;
    setSettings(updated);
    handleChange('scheduler', updated);
  };

  return (
    <Box>
      <Grid container item xs={12}>
        <Grid item xs={12} sx={{ pb: 1.5 }}>
          <Typography variant="h4">Scheduler Tile Information</Typography>
          <Typography sx={{ opacity: 0.5, pt: 0.75, fontSize: "0.8125rem" }}>
            Enable/disable visibility of job information on job tiles
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showCustomerName ?? false}
              control={<Switch color="primary" />}
              label="Customer Name"
              labelPlacement="end"
              name="showCustomerName"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showColor ?? false}
              control={<Switch color="primary" />}
              label="Color"
              labelPlacement="end"
              name="showColor"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showVehicle ?? false}
              control={<Switch color="primary" />}
              label="Vehicle"
              labelPlacement="end"
              name="showVehicle"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showDescription ?? false}
              control={<Switch color="primary" />}
              label="Job Description"
              labelPlacement="end"
              name="showDescription"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showJobHours ?? false}
              control={<Switch color="primary" />}
              label="Job Hours"
              labelPlacement="end"
              name="showJobHours"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showGroup ?? false}
              control={<Switch color="primary" />}
              label="Group"
              labelPlacement="end"
              name="showGroup"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showCycleCounter ?? false}
              control={<Switch color="primary" />}
              label="Cycle Counter"
              labelPlacement="end"
              name="showCycleCounter"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showVin ?? false}
              control={<Switch color="primary" />}
              label="VIN"
              labelPlacement="end"
              name="showVin"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              checked={settings?.showAssignee ?? false}
              control={<Switch color="primary" />}
              label="Assignee"
              labelPlacement="end"
              name="showAssignee"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        {settings?.showCycleCounter && (
          <Grid container sx={{ pt: 3 }}>
            <Grid item xs={12} sx={{ pb: 1.5 }}>
              <Typography variant="h4">Cycle Counter Preferences</Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  checked={settings?.useClockTimer ?? false}
                  control={<Switch color="primary" />}
                  label="Use Clock Timer"
                  labelPlacement="end"
                  name="useClockTimer"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SchedulerSettings;