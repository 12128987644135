import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";

const AssigneeDialog = ({
  open,
  selectedRow,
  departments,
  assignees,
  handleClose,
  handleSubmit,
}) => {
  const [departmentAssignees, setDepartmentAssignees] = useState([]);

  useEffect(() => {
    if (selectedRow && departments) {
      const initialAssignees = departments.map((dept) => {
        const departmentAssignees = selectedRow.assignees.filter(
          (assignee) => assignee.departmentId === dept.departmentId
        );

        const defaultAssignee = departmentAssignees.find(
          (assignee) => !assignee.notifyOnly
        )?.contactId;

        const additionalAssignees = departmentAssignees
          .filter((assignee) => assignee.notifyOnly)
          .map((assignee) => ({
            contactId: assignee.contactId,
            notifyOnly: true,
          }));

        return {
          departmentId: dept.departmentId,
          defaultAssigneeID: defaultAssignee || "",
          additionalAssignees,
        };
      });
      setDepartmentAssignees(initialAssignees);
    }
  }, [selectedRow, departments]);

  const handleDefaultAssigneeChange = (departmentId, contactId) => {
    setDepartmentAssignees((prevState) =>
      prevState.map((entry) =>
        entry.departmentId === departmentId
          ? { ...entry, defaultAssigneeID: contactId || "" }
          : entry
      )
    );
  };

  const handleAdditionalAssigneesChange = (
    departmentId,
    selectedContactIds
  ) => {
    const defaultAssigneeID = departmentAssignees.find(
      (entry) => entry.departmentId === departmentId
    )?.defaultAssigneeID;

    // Filter out the default assignee from additional assignees
    const filteredContactIds = selectedContactIds.filter(
      (contactId) => contactId !== defaultAssigneeID
    );

    setDepartmentAssignees((prevState) =>
      prevState.map((entry) =>
        entry.departmentId === departmentId
          ? {
              ...entry,
              additionalAssignees: filteredContactIds.map((contactId) => ({
                contactId,
                notifyOnly: true,
              })),
            }
          : entry
      )
    );
  };


  const handleSave = () => {
    const formattedAssignees = departmentAssignees.flatMap((entry) => {
      const hasDefault = entry.defaultAssigneeID;
      const hasAdditional = entry.additionalAssignees.length > 0;
      if (!hasDefault && !hasAdditional) return [];

      const result = [];
      if (hasDefault) {
        result.push({
          departmentId: entry.departmentId,
          contactId: entry.defaultAssigneeID,
          notifyOnly: false,
        });
      }
      result.push(
        ...entry.additionalAssignees.map((assignee) => ({
          departmentId: entry.departmentId,
          contactId: assignee.contactId,
          notifyOnly: true,
        }))
      );
      return result;
    });

    handleSubmit(formattedAssignees);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Manage Default Assignees and Additional Notifications (By Department)
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          {departments.map((department) => (
            <Grid
              container
              item
              key={department.departmentId}
              spacing={2}
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography variant="h4">{department.description}</Typography>
              </Grid>

              {/* Default Assignee Dropdown */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Default Assignee</InputLabel>
                  <Select
                    value={
                      departmentAssignees.find(
                        (entry) =>
                          entry.departmentId === department.departmentId
                      )?.defaultAssigneeID || ""
                    }
                    onChange={(e) =>
                      handleDefaultAssigneeChange(
                        department.departmentId,
                        e.target.value
                      )
                    }
                  >
                    {/* Adding "none" option for clearing */}
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {assignees.map((assignee) => (
                      <MenuItem
                        key={assignee.contactId}
                        value={assignee.contactId}
                      >
                        {assignee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Additional Assignees Dropdown */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Additional Notifications</InputLabel>
                  <Select
                    multiple
                    value={
                      departmentAssignees
                        .find(
                          (entry) =>
                            entry.departmentId === department.departmentId
                        )
                        ?.additionalAssignees.map((a) => a.contactId) || []
                    }
                    onChange={(e) =>
                      handleAdditionalAssigneesChange(
                        department.departmentId,
                        e.target.value
                      )
                    }
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            assignees.find(
                              (assignee) => assignee.contactId === id
                            )?.name
                        )
                        .join(", ")
                    }
                  >
                    {assignees.map((assignee) => (
                      <MenuItem
                        key={assignee.contactId}
                        value={assignee.contactId}
                      >
                        <Checkbox
                          checked={departmentAssignees
                            .find(
                              (entry) =>
                                entry.departmentId === department.departmentId
                            )
                            ?.additionalAssignees.some(
                              (a) => a.contactId === assignee.contactId
                            )}
                        />
                        <ListItemText primary={assignee.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSave} variant="outlined" color="primary">
          SUBMIT
        </Button>
        <Button onClick={handleClose} variant="outlined">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssigneeDialog;
