import { roState } from "const";
import useAuth from "hooks/useAuth";
import useDepartments from "hooks/useDepartments";
import useSessionStorage from "hooks/useSessionStorage";
import PropTypes from "prop-types";
import { createContext, useMemo, useState } from "react";
import API from "services/Api";
import { useStateValue } from "store";
// initial state
const jobDateFilters = [
  "all-jobs",
  "today-jobs",
  "past-due-jobs",
  "all-future",
];

const defaultFilters = {
    Jobs: "all-jobs",
    Upcoming: "future-3",
    Groups: "all-groups",
    Assignees: "all-assignees",
    Badges: "all-badges",
    States: "all-states",
  };

const jobsFunctions = {
  filterJobs: () => {},
  getOpenJobs: () => {},
  updateOpenJobs: () => {},
  isDefaultFilter: () => {},
  onSelectActiveJob: () => {},
  resetJobContext: () => {},
};
const initialState = {
  ...jobsFunctions,
};

const JobsContext = createContext(initialState);

const JobsProvider = ({ children }) => {
  const { selectedLocation } = useAuth();
  const { departmentsOrder } = useDepartments()
  const [{jobs}, dispatch] = useStateValue()
  const [openJobs, setOpenJobs] = useState([]);
  const [jobDetails, setJobDetails] = useState([])
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [error, setError] = useState("")
  const [isInitialized, setInitialized] = useState(false)
  const [activeJob, setActiveJob] = useSessionStorage("loc-active-job", "new");
  const jobFilters = JSON.parse(
    sessionStorage.getItem(`${selectedLocation}-filters`)
  );
  const openJobCount = openJobs.length || 0
  const filteredJobCount = filteredJobs.length || 0
  const resetJobContext = () => {
    setOpenJobs([]);
    setFilteredJobs([])
    setError("")
    setInitialized(false)
  };
  const isDefaultFilter = (obj) => {
    return (
      obj.Jobs === defaultFilters.Jobs &&
      obj.Upcoming === defaultFilters.Upcoming &&
      obj.Groups === defaultFilters.Groups &&
      obj.Assignees === defaultFilters.Assignees &&
      obj.Badges === defaultFilters.Badges &&
      obj.States === defaultFilters.States
    );
  };

  const updateOpenJobs = (jobs) => {
    setOpenJobs(jobs)
    filterJobs(jobs)
  }

const sortedJobsByDepartmentsOrder = (jobs) => {
  let allJobs = [];
  let allJobDetails = {};
  const priorityJobs = []; // Initialize priorityJobs array

  console.log("Departments Order:", departmentsOrder);
  console.log("Jobs:", jobs);

  departmentsOrder.forEach((d) => {
    let jobDetails = {
      priorityJobs: [],
      jobs: [],
    };

    let departmentJobs = jobs.filter((job) => job.departmentShortName === d);
    console.log(`Department ${d} Jobs:`, departmentJobs);

    const prioritizedJobs = departmentJobs
      .filter((job) => job.priority > 0)
      .sort((a, b) => a.priority - b.priority);

    console.log(`Department ${d} Prioritized Jobs:`, prioritizedJobs);

    let sortedJobs = [
      ...prioritizedJobs,
      ...departmentJobs.filter((job) => job.priority === 0),
    ];

    sortedJobs.forEach((job) => {
      allJobs.push(job); // Add job to sortedJobs
      jobDetails.jobs.push(job.jobAdminId); // Store job ID
      if (job.priority > 0) {
        jobDetails.priorityJobs.push(job.jobAdminId); // Add priority job ID
      }
    });

    allJobDetails[d] = jobDetails;
  });

  console.log("All Jobs:", allJobs);
  console.log("Job Details:", allJobDetails);

  setJobDetails(() => allJobDetails);
  dispatch({
    type: "JOBS_UPDATE",
    payload: {
      jobs: allJobs,
      selectedLocation: selectedLocation,
    },
  });
  dispatch({
    type: "DEPARTMENT_JOB_DETAILS_UPDATE",
    payload: allJobDetails,
  });

  return allJobs;
};
 const getOpenJobs = async () => {
   try {
     console.log("Fetching open jobs...");
     setInitialized(false);
     const value = await API.getData("JobAdmins", "open");
     console.log("API Response:", value);
     const jobs = sortedJobsByDepartmentsOrder(
       value.filter((item) => item.roState === roState.enum.open)
     );
     setOpenJobs(jobs);
     setInitialized(true);
   } catch (error) {
     console.error("Error fetching open jobs:", error);
     setError(error.message);
   }
 };
  const onSelectActiveJob = (jobAdminId) => {
    setActiveJob(jobAdminId);
  };

  const filterJobs = (jobs) => {
    const filters = JSON.parse(
      sessionStorage.getItem(`${selectedLocation}-filters`)
    );
    let filteredJobs = jobs ? [...jobs] : [...openJobs];
    
    for (const [key, value] of Object.entries(filters)) {
      if (key === "Jobs" && value !== "all-jobs") {
        filteredJobs = value.includes("future")
          ? getJobsByUpcomingFilter(filteredJobs, filters.Upcoming)
          : getJobsByDateFilter(filteredJobs, value);
      }
      if (!value.toString().includes("all")) {
        switch (key) {
          case "Assignees":
            filteredJobs = getJobsByAssigneeFilter(filteredJobs, value);
            break;
          case "Badges":
          filteredJobs = getJobsByBadgesAndStates(filteredJobs, value, filters["State"]);
            break;
          case "Groups":
          filteredJobs = getJobsByGroupFilter(filteredJobs, value);
            break;
          case "States":
          filteredJobs = getJobsByBadgesAndStates(
            filteredJobs,
            filters["Badges"],
            value,
          );
            break;
        }
      }
    }
    setFilteredJobs(filteredJobs)
    return filteredJobs;
  };
  const getJobsByAssigneeFilter = (jobs, filterValue) => {
    return filterValue === "all-assignees"
      ? jobs
      : jobs.filter(
          (j) =>
            j.jobAssignments !== null &&
            j.jobAssignments.some(
              (ja) => ja.contactId.toString() === filterValue
            )
        );
  };
  const getJobsByGroupFilter = (jobs, filterValue) => {
    return filterValue === "all-groups" ? jobs : jobs.filter(
      (j) => j.group !== null && j.group.shortName === filterValue
    );
  };

  const getJobsByUpcomingFilter = (
    jobs,
    filterValue,
    startDate = new Date(),
    endDate = new Date()
  ) => {
    const newStart = new Date(startDate).setHours(0, 0, 0, 0);
    const newEnd = new Date(endDate).setHours(23, 59, 59);
    switch (filterValue) {
      case "future-1":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 1) 
        );
      case "future-2":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 2) 
        );
      case "future-3":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 3) 
        );
      case "future-4":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 4) 
        );
      case "future-5":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 5) 
        );
      case "future-6":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 6) 
        );
      case "future-7":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 7) 
        );
      case "future-14":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 14) 
        );
      case "future-21":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 21) 
        );
      case "future-month":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart &&
            new Date(a.scheduledOut) <
              new Date(newStart).setMonth(new Date(newStart).getMonth() + 1) 
        );
      case "all-future":
        let resultsAllFuture = jobs.filter(
          (a) =>
            new Date(a.scheduledOut) >= newStart 
        );
        return resultsAllFuture;
      case "future-schedule":
        return jobs.filter(
          (a) =>
            new Date(a.scheduledIn) >= newStart &&
            new Date(a.scheduledIn) <
              new Date(newStart).setDate(new Date(newStart).getDate() + 7)
        );
      default:
        return jobs;
    }
  };

  const getJobsByDateFilter = (jobs, filterValue) => {
    const startDate = new Date().setHours(0, 0, 0, 0);
    switch (filterValue) {
      case "today-jobs":
        return jobs.filter(
          (j) =>
            new Date(j.scheduledOut).getDate() === new Date(startDate).getDate() &&
            new Date(j.scheduledOut).getMonth() === new Date(startDate).getMonth() &&
            new Date(j.scheduledOut).getFullYear() === new Date(startDate).getFullYear()
        );
      case "all-future":
        return jobs.filter((a) => 
          new Date(a.scheduledOut) >= startDate
        );
      case "past-due-jobs":
        return jobs.filter((a) => 
          new Date(a.scheduledOut).setHours(23, 59, 59, 999) < startDate
        );
      default:
        return jobs;
    }
  };

  const getJobsByBadgesAndStates = (jobs, badgekey, statekey) => {
    const hasStateId = statekey && !isNaN(statekey) && statekey !== "all-states";
    const hasBadgeId =
      badgekey && typeof badgekey === "string" && badgekey.length > 0 && badgekey !== "all-badges";
    switch (true) {
      case hasStateId && hasBadgeId:
        return jobs.filter(
          (a) =>
            a.badges !== null &&
            a.badges.some((b) => b.badgeId === badgekey && b.state === parseInt(statekey))
        );
      case hasStateId && !hasBadgeId:
        return jobs.filter(
          (a) =>
            a.badges !== null &&
            a.badges.some((b) => b.state === parseInt(statekey))
        );
      case !hasStateId && hasBadgeId:
        return jobs.filter(
          (a) =>
            a.badges !== null &&
            a.badges.some((b) => b.badgeId === badgekey)
        );
      default:
        return jobs;
    }
    
  };

  const contextValue = useMemo(
    () => ({
      openJobs,
      openJobCount,
      filteredJobs,
      filteredJobCount,
      jobDetails,
      activeJob,
      error,
      isInitialized,
      sortedJobsByDepartmentsOrder,
      isDefaultFilter,
      filterJobs,
      getOpenJobs,
      updateOpenJobs,
      isDefaultFilter,
      onSelectActiveJob,
      resetJobContext,
    }),
    [
      openJobs,
      openJobCount,
      filteredJobs,
      filteredJobCount,
      activeJob,
      error,
      isInitialized,
      isDefaultFilter,
    ]
  );
  return (
    <JobsContext.Provider value={contextValue}>{children}</JobsContext.Provider>
  );
};

JobsProvider.propTypes = {
  children: PropTypes.node,
};

export { JobsProvider, JobsContext };
