import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";
import { chooserColumn, sumHours, sumTotal } from "../reportLogic";
import {
  customer,
  delivered,
  description,
  hat,
  hours,
  jobClass,
  phone,
  scheduledOut,
  total,
  vehicle,
  year,
  make,
  model,
  scheduledIn,
  dateReady,
  arrived,
  repairStart,
  leadTime,
  lagTime,
  cycleTime,
  touchTime,
  productionCycle,
  groupName,
  color,
} from "../reportColumns";

const columns = [
  hat,
  chooserColumn(scheduledIn),
  chooserColumn(arrived),
  chooserColumn(repairStart),
  chooserColumn(dateReady),
  delivered,
  chooserColumn(description),
  scheduledOut,
  phone,
  customer,
  chooserColumn(groupName),
  vehicle,
  chooserColumn(color),
  chooserColumn(year),
  chooserColumn(make),
  chooserColumn(model),
  chooserColumn(leadTime),
  chooserColumn(cycleTime),
  chooserColumn(touchTime),
  chooserColumn(productionCycle),
  chooserColumn(lagTime),
  hours,
  jobClass,
  total,
];

const OutboundVehicle = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();

  const calculateCustomSummary = (options) => {
    if (options.name === "DeliveredCount") {
      getTotalDeliveredCount(options);
    }
  };

   const calculateCustomGroupSummary = (options) => {
     if (options.name === "DeliveredCount") {
       if (options.summaryProcess === "start") {
         options.totalValue = 0;
       }
       if (options.summaryProcess === "calculate") {
         if (options.value.datePickup !== null) {
           options.totalValue++;
         }
       }
     }
   };

  const getTotalDeliveredCount = (options) => {
    if (options.summaryProcess === "start") {
      options.totalValue = 0;
    } else if (options.summaryProcess === "calculate") {
      let total = 0;
      data.forEach((element) => {
        if (element.datePickup !== null) {
          total++;
        }
      });
      options.totalValue = total;
    }
  };

  const customDelivered = (data) => {
     return data.value
  }

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
            scheduledInDate: new Date(d.scheduledIn).toLocaleDateString(),
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
          height="100%"
          dataSource={data}
          keyExpr="hat"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          <Summary
            calculateCustomSummary={calculateCustomGroupSummary}
          >
            <TotalItem
              column="hat"
              summaryType="count"
              displayFormat="Job Count: {0}"
            />
            <TotalItem
              name="DeliveredCount"
              summaryType="custom"
              showInColumn="datePickup"
              displayFormat="Delivered: {0}"
            />
            <TotalItem
              column="hours"
              summaryType="sum"
              customizeText={sumHours}
            />
            <TotalItem
              column="subTotal"
              summaryType="sum"
              customizeText={sumTotal}
            />
            <GroupItem
              column="hat"
              summaryType="count"
              showInGroupFooter={false}
              displayFormat="Job Count: {0}"
            />
            <GroupItem
              name="DeliveredCount"
              summaryType="custom"
              showInGroupFooter={false}
              alignByColumn={true}
              displayFormat="Delivered: {0}"
            />
            <GroupItem
              column="hours"
              summaryType="sum"
              displayFormat="Hours: {0}"
              alignByColumn={false}
              showInGroupFooter={false}
            />
            <GroupItem
              column="subTotal"
              summaryType="sum"
              customizeText={sumTotal}
              displayFormat="Total: {0}"
              alignByColumn={false}
              showInGroupFooter={false}
            />
          </Summary>
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
          <Column
            dataField="scheduledInDate"
            caption="Scheduled In Date"
            dataType="date"
          />
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default OutboundVehicle;
