// Data to display on jobs, reuseable and smoother rendering
import useAssignees from "hooks/useAssignees";
import useDepartments from "hooks/useDepartments";
import { getLocalDateTimeString } from "helpers";
import { getFloatValue } from "helpers/format";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { getJobVehicle } from "helpers/jobs";
import { Tooltip } from "@mui/material";
import { isNull } from "lodash";
import { department } from "views/pages/reports-page/reports/reportColumns";
var momentDurationFormatSetup = require("moment-duration-format");

const OverflowTooltip = ({ children, title, placement, childClass }) => {
  momentDurationFormatSetup(moment);
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    );
  });

  return (
    <Tooltip
      title={title || ""}
      placement={placement}
      disableHoverListener={!isOverflowed}
    >
      <div ref={textElementRef} className={childClass}>
        {children}
      </div>
    </Tooltip>
  );
};
export const JobCustomerName = ({ customerName }) => (
  <OverflowTooltip
    title={customerName}
    placement="top"
    childClass="h5 card-name"
  >
    {customerName}
  </OverflowTooltip>
);
export const JobDescription = ({ description }) => (
  <OverflowTooltip
    title={description}
    placement="top"
    childClass="h5 card-description"
  >
    {description}
  </OverflowTooltip>
);
export const JobGroup = ({ group }) => {
  const MemoizedGroup = useMemo(
    () => (group && group?.name != "" ? group.name : "<Group>"),
    [group]
  );
  return <>{MemoizedGroup}</>;
};
export const JobVehicleColor = ({ color }) => {
  const MemoizedColor = useMemo(() => color ?? "<Color>", [color]);
  return <span>{MemoizedColor}&nbsp;</span>;
};
export const JobVehicle = ({ job }) => {
  const vehicle = useMemo(() => getJobVehicle(job), [job]);
  return <span>{vehicle}</span>;
};
export const JobVehicleVin = ({ vin }) => {
  const MemoizedVIN = useMemo(() => (vin && vin != "" ? vin : "<VIN>"), [vin]);
  return <>{MemoizedVIN}</>;
};
export const JobHours = ({ hours, refinish }) => {
  const MemoizedHours = useMemo(
    () => (hours ? "TTL:" + getFloatValue(hours) : "<Hours>"),
    [hours]
  );
  const MemoizedRefinish = useMemo(
    () => (refinish != 0 ? " R:" + getFloatValue(refinish) : ""),
    [refinish]
  );
  return (
    <span>
      {MemoizedHours}
      {MemoizedRefinish}
    </span>
  );
};
export const JobScheduledIn = ({ scheduledIn }) => {
  const MemoizedScheduledIn = useMemo(
    () => getLocalDateTimeString(scheduledIn, "MM/DD") ?? "<schOut>",
    [scheduledIn]
  );
  return <>{MemoizedScheduledIn}</>;
};
export const JobScheduledOut = ({ scheduledOut }) => {
  const MemoizedScheduledOut = useMemo(
    () => getLocalDateTimeString(scheduledOut, "MM/DD") ?? "<schOut>",
    [scheduledOut]
  );
  return <>{MemoizedScheduledOut}</>;
};

export const JobCycleCounter = ({ startDate }) => {
  const [currentTime, setCurrentTime] = useState(moment(startDate));

  useEffect(() => {
    let timer;
    if (startDate instanceof Date) {
      setCurrentTime(moment(startDate));
      timer = setInterval(() => {
        setCurrentTime((prevTime) => prevTime.add(1, "minutes"));
      }, 60000); // 60000 milliseconds = 1 minute
    }

    return () => clearInterval(timer);
  }, [startDate]);

  const memoizedCycle = useMemo(() => {
    const currentTimeInMinutes = moment().diff(currentTime, "minutes");
    return moment
      .duration(currentTimeInMinutes, "minutes")
      .format("D[D] H[H] m[M]");
  }, [currentTime]);

  return <>{memoizedCycle}</>;
};

// export const JobCycleCounter = ({ timeInDept }) => {
//   const [currentTimeInDept, setCurrentTimeInDept] = useState(timeInDept);

//   useEffect(() => {
//     let timer;
//     if (typeof timeInDept === "number") {
//       setCurrentTimeInDept(timeInDept);
//       timer = setInterval(() => {
//         setCurrentTimeInDept((prevTime) => prevTime + 1);
//       }, 60000); // 60000 milliseconds = 1 minute
//     }

//     return () => clearInterval(timer);
//   }, [timeInDept]);

//   const memoizedCycle = useMemo(() => {
//     return moment
//       .duration(currentTimeInDept, "minutes")
//       .format("D[D] H[H] m[M]");
//   }, [currentTimeInDept]);

//   return <>{memoizedCycle}</>;
// };
export const JobCurrentDepartmentAssignee = ({
  jobAssignments,
  departmentShortName,
}) => {
  const { departments } = useDepartments();
  const { locationAssignees } = useAssignees();
  const getDepartmentId = () => {
    const department = departments.find(
      (d) => d.shortName == departmentShortName
    );
    return department?.departmentId ?? "";
  };

  const getCurrentAssigneeName = () => {
    const contactId =
      jobAssignments?.find((a) => a.departmentId == getDepartmentId())
        ?.contactId ?? "";
    const assignee = locationAssignees?.find((a) => a.contactId == contactId);
    return assignee ? assignee.name : "<Assignee>";
  };
  return <>{getCurrentAssigneeName()}</>;}