import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Grid,
  Typography,
  Chip,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { EditNotificationsRounded } from "@mui/icons-material";
import { Box } from "@mui/system";

const NotifyAssigneeDialog = ({
  open,
  handleClose,
  assigneeList,
  selectedAssignees,
  saveSelected,
}) => {
  const [selected, setSelected] = useState([]);

  const handleSelect = (event) => {
    setSelected(event.target.value);
  };

  const handleClear = () => {
    setSelected([]);
  };

  const handleSave = () => {
    saveSelected(selected);
    handleClose();
  };

  useEffect(() => {
    setSelected(selectedAssignees)
  }, [selectedAssignees])

  return (
    <Dialog open={open} maxWidth={"md"} sx={{ p: 0 }}>
      <DialogTitle>
        <Typography>
          <EditNotificationsRounded
            color="primary"
            style={{ verticalAlign: "middle" }}
          />{" "}
          Additional Notifications
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="assignee-select-label">Assignees</InputLabel>
              <Select
                labelId="assignee-select-label"
                id="assignee-select"
                multiple
                value={selected}
                onChange={handleSelect}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </div>
                )}
              >
                {assigneeList.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClear} variant="outlined" color="secondary" disabled={selected.length === 0}>
          Clear
        </Button>
        <Box sx={{flexGrow: 1}} />
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>

        <Button onClick={handleSave} variant="outlined">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotifyAssigneeDialog;
