import { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  Tooltip,
} from "@mui/material";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import CircleIcon from "@mui/icons-material/Circle";
import useSettings from "hooks/useSettings";

const currentAssigneeObject = {
  contactId: "00000000-0000-0000-0000-000000000000",
  isActive: true,
  name: "Current Assignee",
  cellular: "",
  email: "",
  avatar: "",
  doNotify: false,
};

const StateDialog = ({
  open,
  handleClose,
  handleSubmit,
  rowIndex,
  data,
  assignees
}) => {
  const {
    originSettingData
  } = useSettings();
  const [stateRows, setRows] = useState([]);

  const notificationAssignees = [currentAssigneeObject, ...assignees]
  useEffect(() => {
    setRows(structuredClone(data?.badgeStates));
  }, [data]);

  const setValid = (index, isValid) => {
    const rows = structuredClone(stateRows);
    rows[index].isValid = isValid;
    setRows(rows);
  };

  const handleChange = (evnt, index) => {
    const { name, value } = evnt.target;
    const rows = structuredClone(stateRows);
    rows[index][name] = value;
    setRows(rows);
  };

  const handleAssigneeChange = (index, selectedAssignees) => {
    const rows = structuredClone(stateRows);

    const originalBadgeNotifications =
      originSettingData?.[rowIndex]?.badgeStates?.[index]?.badgeNotifications || [];
    rows[index].badgeNotifications = selectedAssignees.map((contactId) => {
      const originalNotification = originalBadgeNotifications.find(
        (notification) => notification.contactId === contactId
      );

      return {
        badgeStateId: rows[index].badgeStateId,
        contactId,
        badgeNotificationId: originalNotification
          ? originalNotification.badgeNotificationId
          : "00000000-0000-0000-0000-000000000000",
      };
    });
    setRows(rows);
  };



  const handleSubmitStates = () => {
    handleSubmit(stateRows);
  };

  return (
    <ConfirmDialog
      open={open}
      onYes={handleSubmitStates}
      onCancel={handleClose}
      contentSx={{ width: "500px" }}
      disableBackdropClick={true}
      disableYes={stateRows?.some((state) => state.description.trim() === "")}
      title={data?.description}
      yesText="SUBMIT"
      maxWidth={"500px"}
    >
      <Box sx={{ p: 0, width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "400px" }}>
          <Table size="small" stickyHeader>
            {stateRows && (
              <TableBody>
                {stateRows
                  .sort((a, b) => a.state - b.state)
                  .map((state, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <CircleIcon sx={{ color: state.color }} />
                      </TableCell>
                      <TableCell sx={{ py: 1, textAlign: "center" }}>
                        <TextField
                          fullWidth
                          error={
                            state.isValid !== undefined ? !state.isValid : false
                          }
                          label="State"
                          value={state.description}
                          name="description"
                          onChange={(evnt) => handleChange(evnt, index)}
                          variant="standard"
                          autoComplete="off"
                          onBlur={(e) => setValid(index, e.target.value !== "")}
                        />
                      </TableCell>
                      <TableCell sx={{ py: 1 }} width={"180px"}>
                        <FormControl fullWidth variant="standard">
                          <InputLabel mb={1}>Notifications</InputLabel>
                          <Select
                            multiple
                            fullWidth
                            value={
                              state.badgeNotifications?.map(
                                (notification) => notification.contactId
                              ) || []
                            }
                            onChange={(e) =>
                              handleAssigneeChange(index, e.target.value)
                            }
                            renderValue={(selected) => {
                              const fullText = selected
                                .map(
                                  (id) =>
                                    notificationAssignees.find(
                                      (assignee) => assignee.contactId === id
                                    )?.name
                                )
                                .join(", ");

                              return (
                                <Tooltip title={fullText} arrow>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "170px"
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "170px"
                                      }}
                                    >
                                      {fullText}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              );
                            }}
                          >
                            {notificationAssignees.map((assignee) => (
                              <MenuItem
                                key={assignee.contactId}
                                value={assignee.contactId}
                              >
                                <Checkbox
                                  checked={state.badgeNotifications?.some(
                                    (notification) =>
                                      notification.contactId ===
                                      assignee.contactId
                                  )}
                                />
                                <ListItemText primary={assignee.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </ConfirmDialog>
  );
};

export default StateDialog;
