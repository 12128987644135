import SettingsContainer from "../SettingsContainer";

const tabOptions = [
  {
    to: "/settings/appointments/appointmenttypes",
    label: "Appointment Types",
    tabId: 0,
  },
];

const AppointmentSettingsContainer = () => {
  return <SettingsContainer tabOptions={tabOptions} maxWidth={"800px"} />;
};

export default AppointmentSettingsContainer;
