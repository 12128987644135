import {
    TableRow,
    TableCell,
    Typography,
    Tooltip,
    TextField,
    IconButton
  } from "@mui/material";
import { useEffect, useState } from "react";  
import Switch from "@mui/material/Switch";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from "@mui/icons-material/Delete";
import IconPicker from "hdc-components/IconPicker";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import StateDialog from "./StateDialog";

const BadgeRows = ({
  data,
  columns,
  assignees
}) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [ badges, setRows ] = useState();
  const [ selectedIcon, setSelectedIcon ] = useState('');
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ selectedRow, setSelectedRow ] = useState(null);
  const [ openIconPicker, setOpenIconPicker ] = useState(false);
  const [ openStatesDialog, setOpenStatesDialog ] = useState(false);
  const [ openDeleteConfirm, setDeleteConfirm ] = useState(false);
  const [ deleteRowIndex, setDeleteRowIndex ] = useState();
  const [ isValidList, setIsValidList ] = useState([]);
  const [ isRowAdded, setIsRowAdded ] = useState();
  const [ focusNewRow, setFocusNewRow ] = useState([]);

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList([...data].map((row) => { return row.description.trim() != ""}));
    data.forEach(row => {
      if (row.badgeId.startsWith(defaultSettingGuid)
        && (focusNewRow.indexOf(row.badgeId) < 0)) {
          setIsRowAdded(true);
          setFocusNewRow([...focusNewRow, row.badgeId]);
      }
    });
  },[data]);

  useEffect(() => {
    if(selectedIcon){
      setOpenIconPicker(true);
    }
  },[selectedIcon]);

  const setValid = (index) => {
    isValidList[index] = [...badges][index].description.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };
  
  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(badges);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleClickState = (index) => {
    setSelectedIndex(index);
    setSelectedRow(badges[index]);
    setOpenStatesDialog(true);
  };

  const handleSubmitStates = (stateRows) => {
    const rows = structuredClone(badges);
    rows[selectedIndex].badgeStates = stateRows;
    setUISettingData(rows);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpenStatesDialog(false);
    setSelectedIndex(-1);
    setSelectedRow(null);
  };

  const handleClickIcon = (index, iconName) => {
    setSelectedIndex(index);
    setSelectedIcon(iconName);
  };

  const handleSelectIcon = (iconName) => {
    const rows = structuredClone(badges);
    rows[selectedIndex].iconName = iconName;
    setUISettingData(rows);
    handleCancel();
  };

  const handleCancel = () => {
    setSelectedIcon('');
    setSelectedIndex(-1);
    setOpenIconPicker(false);
  };
  
  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex('');
    setDeleteConfirm(false);
    setUISettingData(structuredClone(badges).filter((row, index) => index !== deleteRowIndex));
    setIsRowAdded(false);
  }; 

  return (
    <>
      {badges?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell width={columns[0].width} sx={{textAlign:"center"}}>
                <Tooltip title="Modify Icon" placement="top">
                  <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleClickIcon(index, row.iconName)}
                      sx={{width:"34px", height:"34px"}}
                  >
                    <div className={`fa-solid fa-${row.iconName}`} />
                  </IconButton>
                </Tooltip>
            </TableCell>

            <TableCell width={columns[1].width}>
              <TextField
                fullWidth
                autoFocus={isRowAdded}
                error={(!isValidList[index] && (isRowAdded ? badges?.length != index + 1 : true))}
                id="standard-required"
                label="Category"
                value={row.description}
                name="description"
                onChange={(e) => {
                  row.description = e.target.value;
                  handleChange(index, e.target.name, row.description)
                }}
                variant="standard"
                autoComplete="off"
                onBlur={(e) => setValid(index)}
              />
            </TableCell>

            <TableCell width={columns[2].width} sx={{textAlign:"center"}}>
              <Tooltip title="Modify States" placement="top">
                <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleClickState(index)}
                >
                    <AppRegistrationIcon />
                </IconButton>
              </Tooltip>

            </TableCell>

            <TableCell width={columns[3].width} sx={{textAlign:"center"}}>
              <Switch              
                checked={row.isActive}
                onChange={(e) => handleChange(index, e.target.name, e.target.checked)}
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[4].width} sx={{textAlign:"center"}}>
                <Tooltip title="Delete Badge" placement="top">
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickDelete(index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      <IconPicker
        open={openIconPicker}
        selectedIcon={selectedIcon}
        handleSubmit={handleSelectIcon}
        handleCancel={handleCancel}
        contentSx={{width:"648px", height:"550px"}}
      />
      <StateDialog
        open={openStatesDialog}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitStates}
        rowIndex={selectedIndex}
        data={selectedRow}
        assignees={assignees}
      />
      <ConfirmDialog
          open={openDeleteConfirm}
          onYes={handleDelete}
          onNo={handleCloseDeleteConfirm}
          contentSx={{maxWidth:"360px"}}
          disableBackdropClick={true}
          >
          <Typography variant="span">Are you sure you want to delete this badge?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default BadgeRows;
  