import React, { createContext, useContext, useState, useEffect } from "react";
import API from "services/Api";
import { publishItemIsDirty } from "services/PubSubPublisher";
import { usePubSub } from "./PubSubContext";
import useAuth from "hooks/useAuth";

export const AppointmentsContext = createContext();

export const AppointmentsProvider = ({ children }) => {
  const { client } = usePubSub();
  const { selectedLocation } = useAuth();
  const [events, setEvents] = useState([]);
  const [originalEvents, setOriginalEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [date, setSelectedDate] = useState(new Date());

  const changeDate = (date) => {
    setSelectedDate(date);
  };

  const fetchAppointments = async () => {
    const dateString = `${date.getFullYear()}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}`;
    try {
      const data = await API.getData("Appointment", "", `date=${dateString}`);
      setOriginalEvents(data);
    } catch (error) {
      console.error("Failed to fetch appointments", error);
    }
  };

  // get Appointments when month or year changes
  useEffect(() => {
    const fetchAppointments = async (date) => {
      const dateString = `${date.getFullYear()}-${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}`;
      try {
        const data = await API.getData("Appointment", "", `date=${dateString}`);
        setOriginalEvents(data);
      } catch (error) {
        console.error("Failed to fetch appointments", error);
      }
    };

    fetchAppointments(date);
  }, [date.getFullYear(), date.getMonth()]);

  // format Appointments for Calendar UI
  useEffect(() => {
    const formattedEvents = originalEvents.map((appointment) => ({
      id: appointment.appointmentId,
      title: appointment.description,
      start: new Date(appointment.startDateTime),
      end: new Date(appointment.endDateTime),
      allDay: appointment.allDay,
      apptCategoryId: appointment.apptCategoryId,
      type: appointment.apptCategory?.description,
      color: appointment.apptCategory?.displayColor,
      jobAdminId: appointment.jobAdminId,
      clientName: appointment.clientName,
      clientCellular: appointment.clientCellular,
      year: appointment.year,
      make: appointment.make,
      model: appointment.model,
      vehicleColor: appointment.color,
      notes: appointment.notes,
    }));
    setEvents(formattedEvents);
  }, [originalEvents]);

  // get Appointment Types
  useEffect(() => {
    const fetchAppointmentTypes = async () => {
      try {
        const data = await API.getData("Appointment", "ApptCategory");
        const formattedTypes = data.map((type) => ({
          apptCategoryId: type.apptCategoryId,
          type: type.description,
          color: type.displayColor,
        }));
        ;
        setAppointmentTypes(formattedTypes);
      } catch (error) {
        console.error("Failed to fetch appointment types", error);
      }
    };

    fetchAppointmentTypes();
  }, []);

  const addEvent = (event) => {
    API.postData("Appointment", event).then((result) => {
      console.log("Added New Appt", result);
      setOriginalEvents([...originalEvents, result]);
      // Send message to SignalR Subscribers
      publishItemIsDirty(
        client,
        selectedLocation,
        "appointment",
        result.appointmentId,
        "add"
      );
    });
  };

  const removeEvent = (eventId) => {
    API.deleteData("Appointment", eventId);
    console.log("Deleted Appt", eventId);
    setOriginalEvents(
      originalEvents.filter((event) => event.appointmentId !== eventId)
    );
    // Send message to SignalR Subscribers
    publishItemIsDirty(
      client,
      selectedLocation,
      "appointment",
      eventId,
      "delete"
    );
  };

  const updateEvent = (updatedEvent) => {
    API.putData("Appointment", updatedEvent).then((result) => {
      console.log("Updated Appt", result)
      setOriginalEvents(
        originalEvents.map((event) =>
          event.appointmentId === updatedEvent.appointmentId
            ? result
            : event
        )
      );
      // Send message to SignalR Subscribers
      publishItemIsDirty(
        client,
        selectedLocation,
        "appointment",
        updatedEvent.appointmentId,
        "update"
      );
    });
  };


  const getSelectedEvent = (id) => {
    return originalEvents.find((event) => event.appointmentId === id);
  };

  return (
    <AppointmentsContext.Provider
      value={{
        date,
        changeDate,
        events,
        fetchAppointments,
        addEvent,
        removeEvent,
        updateEvent,
        selectedEvent,
        setSelectedEvent,
        appointmentTypes,
        getSelectedEvent,
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};

// Custom hook to use the AppointmentsContext
export const useAppointments = () => useContext(AppointmentsContext);
