import {
  Calendar as BigCalendar,
  momentLocalizer
} from "react-big-calendar";
import "assets/scss/appointmentcalendar.scss"
import moment from "moment";
const localizer = momentLocalizer(moment);

export default function Calendar(props) {
  return <BigCalendar {...props} localizer={localizer} />
}