import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import { useEffect, useState } from "react";
import AssignmentRow from "./AssignmentRow";
import useDepartments from "hooks/useDepartments";
import useAssignees from "hooks/useAssignees";
import useActionButtons from "hooks/useActionButtons";
import useJobFolder from "hooks/useJobFolder";

const Assignments = (params) => {

  const noassignee = 'No Assignee';
  const noAssigneeId = '00000000-0000-0000-0000-000000000000';
  const actionButtons = useActionButtons();
  const { originalFolder, jobFolder, changeFolder } = useJobFolder();
  const { locationAssignees } = useAssignees();
  const [isEditMode, setEditModeFlag] = useState(params.isEditMode ?? true);
  const [assigneeList, setAssigneeList] = useState([noassignee, 
      ...(locationAssignees.sort((a, b) => (a.name > b.name ? 1 : -1)).filter(a => a.isActive).map(a => { return a.name} ))]);
  const deptContext = useDepartments();
  const [departments, setDepartments] = useState(deptContext.activeDepartments);
  const [currentUIAssignments, setCurrentUIAssignments] = useState([]);

  function getDepartmentName(id) {
    return departments.find(e => e.departmentId === id)?.name;
  }

  function getAssigneeName(id) {
    if (noAssigneeId === id || !id) return noassignee;
    return locationAssignees.find(e => e.contactId === id)?.name;
  }

  function getAssigneeId(name) {
    if(noassignee === name || !name) return noAssigneeId;
    return locationAssignees.find(e => e.name === name)?.contactId;
  }

  function getNewNotifyOnlyAssigneeObject(name, departmentId){
    console.log("New Notify Assignee Id", getAssigneeId(name))
    return {
          jobAssignmentId: noAssigneeId, // Optionally generate a new ID if needed
          contactId: getAssigneeId(name), // Function to get the contactId from the name
          departmentId: departmentId, // Same department as the main assignee
          doNotify: true,
          hours: 0, // Default hours for notify-only
          cycleTime: "", // Default cycle time for notify-only
          cycleGoal: "", // Default cycle goal for notify-only
          efficiency: "", // Default efficiency for notify-only
          notifyOnly: true, // Mark as notify-only
        }
  }

  function convertToUIAssignmentsFrom(assignments) {
    
    const notifyOnlyAssigneeMap = assignments.reduce((acc, curr) => {
      if (curr.notifyOnly === true) {
        if (!acc[curr.departmentId]) {
          acc[curr.departmentId] = [];
        }
        acc[curr.departmentId].push(getAssigneeName(curr.contactId));
      }
      return acc;
    }, {});

    const mainAssignments = assignments.filter((a) => a.notifyOnly === false)

    return mainAssignments.map((a) => {
      return {
        departmentId: a.departmentId,
        department: getDepartmentName(a.departmentId),
        assigneeList: [...assigneeList],
        assignee: getAssigneeName(a.contactId),
        notify: a.doNotify,
        notifyAssignees: notifyOnlyAssigneeMap[a.departmentId] || [], // Includes name and jobAssignmentId
        jobAssignmentId: a.jobAssignmentId,
        ct: a.cycleTime,
        cg: a.cycleGoal,
        eff: a.efficiency,
      };
    });
  }

  const getJobAssignmentsForSave = (uiAssignments) => {
    let allAssignments = [];

    uiAssignments.forEach((a) => {
      // Main assignee object
      allAssignments.push({
        jobAssignmentId: a.jobAssignmentId,
        contactId: getAssigneeId(a.assignee), // Resolve the main assignee's contactId
        departmentId: a.departmentId,
        doNotify: a.notify,
        hours: 0,
        cycleTime: a.ct,
        cycleGoal: a.cg,
        efficiency: a.eff,
        notifyOnly: false, // Main assignee is not notify-only
      });

      // Convert notifyAssignees names to full objects
      a.notifyAssignees.forEach((name) => {
        let exists = originalFolder.jobFolder.jobAssignments.find((ja) => {
          return (
            a.departmentId === ja.departmentId &&
            ja.notifyOnly &&
            ja.contactId === getAssigneeId(name)
          );
        });
        let notifyAssigneeObject = exists
          ? exists
          : getNewNotifyOnlyAssigneeObject(name, a.departmentId);
        allAssignments.push(notifyAssigneeObject);
      });
    });

    console.log("original assignees", originalFolder.jobFolder.jobAssignments)
    console.log("Assignees for Save", allAssignments)
    return allAssignments;
  };


  const affectOnChange = () => {
    changeFolder("assignments", getJobAssignmentsForSave(currentUIAssignments));
  };

  useEffect(() => {
    console.log("assignments from folder", jobFolder.jobAssignments)
    setCurrentUIAssignments(convertToUIAssignmentsFrom(jobFolder.jobAssignments));
  }, [jobFolder]);

 useEffect(() => {
   if (params.assignAllValue) {
     const updatedAssignments =
       params.assignAllValue !== "No Assignee"
         ? currentUIAssignments.map((assignment) => ({
             ...assignment,
             assignee: params.assignAllValue,
             notify: true,
           }))
         : currentUIAssignments.map((assignment) => ({
             ...assignment,
             assignee: "No Assignee",
             notify: false,
           }));
     setCurrentUIAssignments(updatedAssignments);

     changeFolder("assignments", getJobAssignmentsForSave(updatedAssignments));
     params.onAssignmentChange()
   }
 }, [params.assignAllValue]);


  return (
    <>
      <Box sx={{ p: 0, px:1, width:'100%', height: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{ maxHeight: '600px'}}>
          <Table size="small" stickyHeader>
            <TableHead>
              {currentUIAssignments?.length == 0 ? null : (
                <TableRow>
                  <TableCell align="center">Departments</TableCell>
                  <TableCell align="center">Assignee</TableCell>
                  {isEditMode && <TableCell align="center">Notify</TableCell>}
                  <TableCell align="center">Additional Notifications</TableCell>
                  <TableCell align="center">Cycle Time</TableCell>
                  <TableCell align="center">Cycle Goal</TableCell>
                  <TableCell align="center">Efficiency</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              <AssignmentRow
                assignmentData={currentUIAssignments}
                isEditMode={isEditMode}
                affectOnChange={affectOnChange}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Assignments;
  