import { useState } from "react";
import { Grid, Toolbar, Typography } from '@mui/material';
import { BadgesProvider } from "contexts/BadgesContext";
import { DepartmentsProvider } from "contexts/DepartmentsContext";
import { GroupsProvider } from "contexts/GroupsContext";
import { JobClassProvider } from "contexts/JobClassContext";
import FormDialog from "hdc-components/FormDialog";
import JobFolder from "views/pages/jobfolder-page";
import useAuth from "hooks/useAuth";
import { isMobile } from "react-device-detect";
import MobileJobFolder from "views/pages/jobfolder-page/MobileJobFolder";

const AddJobModal = ({open, onClose, onOpen, onAdded, jobClass, scheduledIn, initialValues}) => {
  const [ headerColor, setHeaderColor ] = useState('#0e0e0e');
  const { selectedLocation } = useAuth();
  const title = "Create Job";

  return (
    <FormDialog
      open={open}
      handleClose={onClose}
      title={title}
      headerStyle={{
        m: 0,
        p: 0, 
        borderTop: `6px solid ${headerColor}`,
      }}
      titleContent={<>
        {!isMobile &&
        <Toolbar
          variant="dense"
          component={Grid}
          container
          alignContent="center"
        >
          <Typography
            variant="h3"
            component={Grid}
            item
            xs={12}
            md
            alignItems="center"
          >
            {title}
          </Typography>
        </Toolbar>
        }</>
      }
      content={
          <DepartmentsProvider selectedLocation={selectedLocation}>
            <JobClassProvider>
              <GroupsProvider>
                <BadgesProvider>
                  {!isMobile &&
                  <JobFolder onCancel={onClose} setOpen={onOpen} onAdded={onAdded} setHeaderColor={setHeaderColor} jobClass={jobClass} scheduledIn={scheduledIn} initialValues={initialValues}/>
      }
      {isMobile &&
      <MobileJobFolder onCancel={onClose} setOpen={onOpen} onAdded={onAdded} setHeaderColor={setHeaderColor} jobClass={jobClass} scheduledIn={scheduledIn} />}
                </BadgesProvider>
              </GroupsProvider>
            </JobClassProvider>
          </DepartmentsProvider>
      }
    />
  )
}

export default AddJobModal