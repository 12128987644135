import { useTheme } from "@emotion/react";
import {
  Typography,
  Grid,
  Popper,
  Paper,
  Stack
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import useAuth from "hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import API from "services/Api";
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { useStateValue } from 'store';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 120,
  height: 20,
  borderRadius: "54px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "54px",
    backgroundColor: theme.palette.mode === "light" ? "#089000" : "#089000",
  },
}));

const KPI = ({ title, value, total = 100, metrics }) => {
  const kpiRef = useRef(null);
  const [open, setOpen] = useState(false);
  const percent = total == 0 ? 0 : parseInt((value / total * 100).toFixed());
  return (
    <>
      <Box
        display="flex"
        marginRight="11px"
        component={Grid}
        item
        ref={kpiRef}
        alignItems={"center"}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Typography variant="h4" marginRight="7px">
          {title}: {value}
        </Typography>
        <BorderLinearProgress variant="determinate" value={percent} />
      </Box>
      <KPIPopper
        open={open}
        kpiRef={kpiRef}
        title={title}
        capacityPercent={`${percent}%`}
        metrics={metrics}
      />
    </>
  );
};

const KPIPopper = ({ open, kpiRef, title, capacityPercent, metrics }) => {
  const theme = useTheme();
  return (
    <Popper
      placement="bottom"
      open={open}
      anchorEl={kpiRef.current}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ],
      }}
    >
      {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper sx={{mt: 1}}>
              {open && (
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 1 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">
                          {capacityPercent} Capacity
                        </Typography>
                        {title === "INV" && (
                          <Stack>
                            <Typography
                              component="span"
                              variant="h4"
                              sx={{ fontWeight: 400 }}
                            >
                              Pre Production : {metrics.preProduction}
                            </Typography>
                            <Typography
                              component="span"
                              variant="h4"
                              sx={{ fontWeight: 400 }}
                            >
                              Production : {metrics.production}
                            </Typography>
                            <Typography
                              component="span"
                              variant="h4"
                              sx={{ fontWeight: 400 }}
                            >
                              Post Production : {metrics.postProduction}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </MainCard>
              )}
            </Paper>
          </Transitions>
      )}
    </Popper>
  );
};

const KPISection = () => {
  const { selectLocation } = useAuth()
  const isSelected = sessionStorage.getItem("isSelect");
  const [metrics, setMetrics] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [{ kip }, dispatch] = useStateValue();

  useEffect(() => {
    const fetchData = () => {
      API.getData("locations", "metrics")
        .then((response) => {
          setIsLoaded(true);
          setMetrics(response);
        })
        .catch((error) => {
          setError(error);
        });
    };
    if (isSelected !== "") {
      fetchData();
    }
    return () => setIsLoaded(false);
  }, [isSelected, kip.updateRequest, selectLocation]);

  return (
    <Grid container columnSpacing={1}>
      {!isLoaded && (
        <BorderLinearProgress variant="indeterminate" color="primary" />
      )}
      {isLoaded && metrics && (
        <>

          <KPI title={"WIP"} value={metrics.wip || 0} total={metrics.wipMax || 0}/>
          <KPI title="INV" value={metrics.inventory || 0} total={metrics.inventoryMax || 0} metrics={metrics}/>
        </>
      )}
      {!metrics && <KPI title={"NO METRICS"} value={0} total={0} />}
    </Grid>
  );
};

export default KPISection;
