import React from "react";
import { AppointmentsProvider } from "contexts/AppointmentsContext";
import DesktopAppointments from "./DesktopAppointments";
import { AppointmentsFolderProvider } from "contexts/AppointmentsFolderContext";

export default function AppointmentsView() {

  return (
    <AppointmentsProvider>
      <AppointmentsFolderProvider>
      <DesktopAppointments/>
      </AppointmentsFolderProvider>
    </AppointmentsProvider>
  );
}
