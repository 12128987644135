import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import PlaylistAddCheckRoundedIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import PermMediaRoundedIcon from "@mui/icons-material/PermMediaRounded";
import DoorBackRoundedIcon from "@mui/icons-material/DoorBackRounded";
import TextsmsIcon from "@mui/icons-material/Textsms";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Badge,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ToDoTab from "views/pages/jobfolder-page/todo-tab";
import InformationTab from "views/pages/jobfolder-page/information-tab";
import AssignmentsTab from "views/pages/jobfolder-page/assignments-tab";
import MediaTab from "views/pages/jobfolder-page/media-tab";
import ClosingTab from "views/pages/jobfolder-page/closing-tab";
import styled from "@emotion/styled";
import HistoryTab from "views/pages/jobfolder-page/history-tab";
import useDepartments from "hooks/useDepartments";
import useActionButtons from "hooks/useActionButtons";
import API from "services/Api";
import { roState } from "const";
import ConfirmDialog from "views/pages/jobfolder-page/ConfirmDialog";
import useJobFolder from "hooks/useJobFolder";
import { useStateValue } from "store";
import { getRoStateDescription } from "helpers/roState";
import { useTheme } from "@emotion/react";
import ImportJobsModal from "../import-job-modal";
import usePermissions from "hooks/usePermissions";
import useAuth from "hooks/useAuth";
import moment from "moment"
import CloneJobModal from "../clone-job-modal";
import MessagesTab from "views/pages/jobfolder-page/messages-tab";
import { publishItemIsDirty } from "services/PubSubPublisher";
import { usePubSub } from "contexts/PubSubContext";
import { GroupAddRounded } from "@mui/icons-material";
import AssignAllMenu from "./AssignAllMenu";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRadius: "8px",
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledTab = styled((props) => (
  <Tooltip title={props.tooltipTitle} placement="top">
    <Tab disableRipple {...props} />
  </Tooltip>
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "4px",
  left: "16px",
  width: "30px",
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  color: theme.palette.grey.main,
  "&.MuiTab-labelIcon": {
    display: "none",
  },
  "&:hover": {
    color: theme.palette.secondary.main,
    opacity: 1,
    "&.MuiTab-labelIcon": {
      display: "flex",
    },
  },
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: "max-content",
    "&.MuiTab-labelIcon": {
      display: "flex",
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export function TabPanel(props) {
  const { children, value, index, tooltipTitle, badgeContent, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          
            {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function JobFolderTabs({ context }) {
  const theme = useTheme()
  const { user, selectedLocation } = useAuth()
  const { client } = usePubSub()
  const { departments } = useDepartments();
  const actionButtons = useActionButtons();
  const {userPerms} = usePermissions()
  const [{ userLocation, messages }, dispatch] = useStateValue();
  const { users, noteCategories } = userLocation;
  const childRef = useRef();
  const { jobId, jobFolder, folderIndex, setFolderIndex, setOpen, isLoaded } =
    context;
  const [value, setValue] = useState(folderIndex);
  const [checkEnable, setCheckEnable] = useState(0);
  const [forceRender, setForceRender] = useState(0);
  const [headerColor, setHeaderColor] = useState("#0e0e0e");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMarkReadOpen, setConfirmMarkReadOpen] = useState(false)
  const [closeAfterMarkRead, setCloseAfterMarkRead] = useState(false)
  const [importOpen, setImportOpen] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [assignAllValue, setAssignAllValue] = useState("")
  const {
    folder,
    updateJobFolder,
    originalFolder,
    initContext,
    isModified,
    isJobModified,
    isAssignmentsModified,
    isTodosModified,
    revertFolder,
    isJobValid,
    unreadCount,
    jobMessages,
    setMessages,
    addHistoryNoteToFolder,
  } = useJobFolder();

  const increaseCheckEnable = () => {
    setCheckEnable(checkEnable + 1);
  };

  const onRevert = () => {
    actionButtons.setRevert(true);
  };

  const revertToDos = () => {
    revertFolder("todos");
    onRevert();
  };

  const revertAssignments = () => {
    revertFolder("assignments");
    onRevert();
  };

  const onSave = () => {
    if(originalFolder.jobFolder.scheduledOut !== jobFolder.scheduledOut){
      addScheduledOutChangeHistoryNote()
    }
    updateJobFolder();
  };

  const getAnychanges = () => {
    return isModified;
  };

  const saveBeforeExit = () => {
    if (getAnychanges()) {
      setConfirmOpen(true);
    } else {
      setOpen(false);
    }
  };

    const addReadMessagesHistoryNote = () => {
      const category = userLocation.noteCategories.filter(
        (n) => n.description === "Communications" && !n.isCustom
      )[0];
      const note = `Messages were read by ${user.firstName} ${user.lastName}`;
      API.postData(
        "jobadmins",
        {
          notes: note,
          noteCategoryId: category.noteCategoryId,
          userId: user.userId,
        },
        `${jobFolder.jobAdminId}/jobhistory`
      ).then((res) => {
        addHistoryNoteToFolder(res);
      });
    };

    const markAsRead = (phone) => {
      API.putData("Messages", {}, `markallread/${phone}`).then((res) => {
        addReadMessagesHistoryNote();
        let updatedJobMessages = [...jobMessages];
        let updatedLocationMessages = [...messages.messages];
        let updatedLocationUnreadMessages = [...messages.unreadMessages];
        res.forEach((m) => {
          let jobIndex = updatedJobMessages.findIndex(
            (existing) => m.messageHistoryId === existing.messageHistoryId
          );
          let allIndex = updatedLocationMessages.findIndex(
            (existing) => m.messageHistoryId === existing.messageHistoryId
          );
          let unreadIndex = updatedLocationUnreadMessages.findIndex(
            (existing) => m.messageHistoryId === existing.messageHistoryId
          );
          if (jobIndex > -1) {
            updatedJobMessages[jobIndex] = m;
          }
          if (allIndex > -1) {
            updatedLocationMessages[allIndex] = m;
          }
          if (unreadIndex > -1) {
            updatedLocationUnreadMessages.splice(unreadIndex, 1);
          }
        });
        setMessages(updatedJobMessages);
        dispatch({ type: "MESSAGES_UPDATE", payload: updatedLocationMessages });
        dispatch({
          type: "UNREAD_MESSAGES_UPDATE",
          payload: updatedLocationUnreadMessages,
        });
        publishItemIsDirty(client, selectedLocation, "messaging", phone, "read");
      });
    };

    const onMarkRead = () => {
      markAsRead(originalFolder.jobFolder.clientCellular);
      setConfirmMarkReadOpen(false);
      if (closeAfterMarkRead) {
        onClose()
      }
    };

    const onIgnoreMarkRead = () => {
      setConfirmMarkReadOpen(false)
      if (closeAfterMarkRead) {
        onClose();
      }
    }

  const onCancelMarkRead = () => {
    setConfirmMarkReadOpen(false);
      setCloseAfterMarkRead(false);
      setFolderIndex(2)
  }

  const onYes = () => {
    if (originalFolder.jobFolder.scheduledOut !== jobFolder.scheduledOut) {
      addScheduledOutChangeHistoryNote();
    }
    setConfirmOpen(false);
    updateJobFolder(true);
  };

  const onNo = () => {
    initContext("new");
    setConfirmOpen(false);
    setOpen(false);
  };

  const onClose = () => {
    saveBeforeExit();
  };

  const onCloseMessaging = () => {
      if (unreadCount > 0) {
        setConfirmMarkReadOpen(true);
        setCloseAfterMarkRead(true);
      } else {
        onClose()
      }
    }

  const handleChange = (event, newValue) => {
    if(folderIndex === 2){
      if(unreadCount > 0){
        setConfirmMarkReadOpen(true)
      }
    }
    setValue(newValue);
    setFolderIndex(newValue);
  };

  useEffect(() => {
    if (folderIndex !== undefined) {
      setValue(folderIndex);
    }
  }, [folderIndex]);

  useEffect(() => {
    setForceRender(forceRender + 1);
  }, actionButtons.pendingChange);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(async () => {
    const userLocationFetch = await fetchSettings();
    dispatch({
      type: "USERLOCATION_UPDATE",
      payload: userLocationFetch,
    });
  }, []);

  const fetchSettings = async () => {
    try {
      const res = await Promise.all([
        fetch(`${window.location.origin}/api/todos/v1/groups`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${window.location.origin}/api/todos/v1/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${window.location.origin}/api/media/v1/categories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${window.location.origin}/api/notecategories/v1/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      const todoItems = Object.assign(
        {},
        ...data[1]?.map((d) => ({ [d.todoId]: d }))
      );
      const locationSettings = {
        ...userLocation,
        todoGroups: data[0],
        todoItems: todoItems,
        mediaCategories: data[2],
        noteCategories: data[3],
      };
      return locationSettings;
    } catch {
      throw Error("Location settings failed from Production index");
    }
  };

  const addScheduledOutChangeHistoryNote = () => {
    const category = userLocation.noteCategories.filter(
      (n) => n.description === "System"
    )[0];
    const note = `The Scheduled Out date was changed to ${moment(
      jobFolder.scheduledOut
    ).format("MMMM Do YYYY")}`;
    API.postData(
      "jobadmins",
      {
        notes: note,
        noteCategoryId: category.noteCategoryId,
        userId: user.userId,
      },
      `${jobFolder.jobAdminId}/jobhistory`
    ).then((res) => {
      addHistoryNoteToFolder(res);
    })
  };


  const headerTitle = () => {
    if (jobFolder) {
      const jobNumber = `Job ${jobFolder.hat ? jobFolder.hat : `#${jobFolder.jobAdminInt}`}`;
      let tabInfo = `➜ ${tabLabels[value]}`;
      switch(value){
        case 0:
          tabInfo = `(${
          jobFolder.roState == roState.enum.void ||
          jobFolder.roState == roState.enum.archived
            ? getRoStateDescription(jobFolder.roState)
            : departments.find(
                (d) => d.shortName === jobFolder.departmentShortName
              )?.name ?? ""
          })`
          break;
      }
      
      return `${jobNumber} ${tabInfo}`;
    }
  };



  useEffect(() => {
    if (jobFolder) {
      setHeaderColor(jobFolder.jobClass?.displayColor);
    }
  }, [isLoaded]);

  const tabLabels = [
    "Information",
    "History",
    "Messaging",
    "Tasks",
    "Assignments",
    "Media",
    "Archive",
  ];
  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        component={Grid}
        container
        alignItems={"space-between"}
        sx={{
          m: 0,
          p: 0,
          borderTop: `6px solid ${headerColor}`,
        }}
      >
        <Toolbar
          variant="dense"
          component={Grid}
          container
          alignContent="center"
        >
          <Typography
            variant="h3"
            component={Grid}
            item
            xs={12}
            md
            alignItems="center"
          >
            {headerTitle()}
          </Typography>
          {value === 4 && (
            <div>
              <AssignAllMenu onAssignAll={(val) => setAssignAllValue(val)} />
            </div>
          )}
          <Typography
            variant="h5"
            component={Grid}
            item
            md
            alignItems="end"
            textAlign="end"
            sx={{
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          >
            {tabLabels[value]}
          </Typography>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="simple-tab"
            variant="fullWidth"
            component={Grid}
            item
            xs={12}
            md={"auto"}
          >
            <StyledTab
              icon={<FolderOpenRoundedIcon />}
              tooltipTitle="Information"
              {...a11yProps(0)}
              disabled={
                userPerms.jobfolder === 1 || userPerms.jobinformation === 1
              }
            />
            <StyledTab
              icon={<WorkHistoryRoundedIcon />}
              tooltipTitle="History"
              {...a11yProps(1)}
              disabled={
                userPerms.jobfolder === 1 || userPerms.jobinformation === 1
              }
            />
            <StyledTab
              icon={
                <Badge
                  invisible={unreadCount === 0}
                  variant="dot"
                  color="error"
                >
                  <TextsmsIcon />
                </Badge>
              }
              tooltipTitle="Messaging"
              {...a11yProps(2)}
              disabled={
                !userLocation.enableMessaging ||
                userPerms.jobfolder === 1 ||
                userPerms.messaging === 1
              }
            />
            <StyledTab
              icon={<PlaylistAddCheckRoundedIcon />}
              tooltipTitle="Tasks"
              {...a11yProps(3)}
              disabled={userPerms.jobtodolist === 1}
            />
            <StyledTab
              icon={<AssignmentIndRoundedIcon />}
              tooltipTitle="Assignments"
              {...a11yProps(4)}
              disabled={
                userPerms.jobfolder === 1 || userPerms.jobassignments === 1
              }
            />
            <StyledTab
              icon={<PermMediaRoundedIcon />}
              tooltipTitle="Media"
              {...a11yProps(5)}
              disabled={userPerms.jobfolder === 1 || userPerms.jobmedia === 1}
            />
            <StyledTab
              icon={<DoorBackRoundedIcon />}
              tooltipTitle="Archive"
              {...a11yProps(6)}
              disabled={userPerms.jobfolder === 1 || userPerms.jobclosing === 1}
            />
          </StyledTabs>
        </Toolbar>
      </DialogTitle>
      <DialogContent
        sx={{ p: 0, mt: 1, minHeight: "600px", maxHeight: "600px" }}
      >
        <>
          {!isLoaded && <Skeleton height={"100%"} width={"100%"} />}
          {isLoaded && (
            <>
              <TabPanel value={value} index={0} height={"100%"}>
                <InformationTab
                  ref={childRef}
                  checkEnable={increaseCheckEnable}
                  setOpen={setOpen}
                  setHeaderColor={setHeaderColor}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <HistoryTab jobFolder={jobFolder} users={users} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MessagesTab />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ToDoTab jobFolder={jobFolder} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <AssignmentsTab assignAllValue={assignAllValue} onAssignmentChange={() => setAssignAllValue("")}/>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <MediaTab jobId={jobId} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <ClosingTab jobFolder={jobFolder} setOpen={setOpen} />
              </TabPanel>
            </>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <TabPanel value={value} index={0}>
          <Tooltip placement="bottom" title="Link To Estimate">
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => setImportOpen(true)}
              disabled={
                userPerms.jobinformation === 4 ||
                userPerms.jobfolder === 1 ||
                userPerms.jobinformation === 1 ||
                jobFolder?.roState == roState.enum.void ||
                jobFolder?.roState == roState.enum.archived
              }
            >
              LINK
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            sx={{ ml: 1 }}
            disabled={
              !isJobModified ||
              userPerms.jobinformation === 4 ||
              userPerms.jobfolder === 1 ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
            onClick={onRevert}
          >
            REVERT
          </Button>
          <LoadingButton
            variant="outlined"
            loading={actionButtons.loading}
            disabled={
              !isModified ||
              !isJobValid ||
              userPerms.jobinformation === 4 ||
              userPerms.jobfolder === 1 ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
            onClick={onSave}
            sx={{ ml: 1 }}
          >
            SAVE
          </LoadingButton>

          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            EXIT
          </Button>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            EXIT
          </Button>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onCloseMessaging}>
            EXIT
          </Button>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Button
            variant="outlined"
            onClick={revertToDos}
            disabled={
              !isTodosModified ||
              userPerms.jobtodolist === 4 ||
              userPerms.jobfolder === 1 ||
              userPerms.jobtodolist === 1 ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
          >
            REVERT
          </Button>
          <Button
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={onSave}
            disabled={
              !isModified ||
              !isJobValid ||
              userPerms.jobtodolist === 4 ||
              userPerms.jobfolder === 1 ||
              userPerms.jobtodolist === 1 ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
          >
            SAVE
          </Button>
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            EXIT
          </Button>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <Button
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={revertAssignments}
            disabled={
              !isAssignmentsModified ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
          >
            REVERT
          </Button>
          <Button
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={onSave}
            disabled={
              !isModified ||
              !isJobValid ||
              jobFolder?.roState == roState.enum.void ||
              jobFolder?.roState == roState.enum.archived
            }
          >
            SAVE
          </Button>
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            EXIT
          </Button>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            EXIT
          </Button>
        </TabPanel>

        <TabPanel value={value} index={6}>
          {jobFolder?.roState == roState.enum.void ||
          jobFolder?.roState == roState.enum.archived ? (
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => actionButtons.setReopen(true)}
              disabled={
                userPerms.jobclosing === 4 ||
                userPerms.jobfolder === 1 ||
                userPerms.jobclosing === 1
              }
            >
              REOPEN
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={() => actionButtons.setVoid(true)}
                disabled={
                  userPerms.jobclosing === 4 ||
                  userPerms.jobfolder === 1 ||
                  userPerms.jobclosing === 1
                }
              >
                VOID
              </Button>
              <Button
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={() => actionButtons.setComplete(true)}
                disabled={
                  userPerms.jobclosing === 4 ||
                  userPerms.jobfolder === 1 ||
                  userPerms.jobclosing === 1
                }
              >
                ARCHIVE
              </Button>
            </>
          )}
          <Button variant="outlined" sx={{ ml: 1 }} onClick={onClose}>
            EXIT
          </Button>
        </TabPanel>
      </DialogActions>
      <ConfirmDialog
        open={confirmMarkReadOpen}
        onYes={onMarkRead}
        onNo={onIgnoreMarkRead}
        onCancel={onCancelMarkRead}
        customMessage="Would you like to mark messages as read?"
      />
      <ConfirmDialog
        open={confirmOpen}
        onYes={isJobValid ? onYes : null}
        onNo={onNo}
        onCancel={() => setConfirmOpen(false)}
      />
      <ImportJobsModal open={importOpen} onClose={() => setImportOpen(false)} />
      <CloneJobModal open={cloneOpen} onClose={() => setCloneOpen(false)} />
    </>
  );
}
