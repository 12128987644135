import { useState, useRef } from "react";
import {
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Formik, Form } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";
import moment from "moment";
import { useAppointments } from "contexts/AppointmentsContext";
import HeaderFooterCard from "hdc-components/HeaderFooterCard";
import { phoneRegex } from "helpers/regex";
import AddJobModal from "views/modals/add-job-modal";
import useJobFolder from "hooks/useJobFolder";
import { folderRouteButtons } from "views/pages/production-page/JobTile/PopMenu/menuConstants";
import { useStateValue } from "store";
import useAuth from "hooks/useAuth";
import API from "services/Api";

const validationSchema = yup.object({
  clientName: yup.string().trim().required("Name is required"),
  clientCellular: yup
    .string()
    .matches(phoneRegex, "Phone number is not valid")
    .required("Phone number is required"),
  clientEmail: yup.string().email("Enter a valid email"),
  apptCategoryId: yup.string().required("Appointment type is required"),
  year: yup.number().nullable(),
  make: yup.string().nullable(),
  model: yup.string().nullable(),
  color: yup.string().nullable(),
  description: yup.string().required("Description is Required"),
});

const createBlankAppointment = () => ({
  apptCategoryId: "",
  startDateTime: new Date(),
  endDateTime: moment().add(1, "hour").toDate(),
  allDay: false,
  clientReminder: false,
  description: "",
  clientName: "",
  clientCellular: "",
  clientEmail: "",
  year: null,
  make: "",
  model: "",
  color: "",
  notes: "",
  jobAdminId: "",
});

const AppointmentsFolder = ({
  appointmentDetails,
  setHeaderColor,
  onClose,
}) => {
  const {
    appointmentTypes,
    getSelectedEvent,
    addEvent,
    removeEvent,
    updateEvent,
  } = useAppointments();
  const { user } = useAuth();
  const [{ userLocation }] = useStateValue();
  const { folder, setFolder } = useJobFolder();
  const formRef = useRef(null);
  const notesInputRef = useRef(null);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [initialJobValues, setInitialJobValues] = useState(null);

  const createExistingEvent = (id) => {
    let event = getSelectedEvent(id);
    return event ? event : appointmentDetails;
  };

  const handleCreateAppointment = (values) => {
    const newAppointment = {
      ...values,
    };
    console.log("New Appointment To Be Created", newAppointment);
    addEvent(newAppointment);
    onClose();
  };

  const handleUpdateAppointment = (values, actions) => {
    const updatedAppointment = {
      ...values,
    };
    console.log("Appointment To Be Updated", updatedAppointment);
    updateEvent(updatedAppointment);

    actions.resetForm({ values: updatedAppointment });
  };

  const handleDeleteAppointment = () => {
    removeEvent(appointmentDetails.id);
    setDeleteDialogOpen(false);
    onClose();
  };

  const handleConvertToJob = (values) => {
    // Logic to convert appointment to job
    console.log("Convert appointment to job");
    setInitialJobValues({
      clientName: values.clientName,
      clientCellular: values.clientCellular,
      clientEmail: values.clientEmail,
      year: values.year,
      make: values.make,
      model: values.model,
      color: values.color,
    });
    setIsJobModalOpen(true);
  };

  const addAppointmentNotesToJobNotes = (jobAdminId, notes) => {
    const category = userLocation.noteCategories.filter(
      (n) => n.description === "System" && !n.isCustom
    )[0];
    const note = `Notes from Appointment: "${notes}"`;
    API.postData(
      "jobadmins",
      {
        notes: note,
        noteCategoryId: category.noteCategoryId,
        userId: user.userId,
      },
      `${jobAdminId}/jobhistory`
    );
  };

  const handleJobCreated = (job, setFieldValue, resetForm) => {
    console.log("handling created job from appointment", job);
    // Update the appointment with the new jobAdminId
    const updatedAppointment = {
      ...createExistingEvent(appointmentDetails.id),
      jobAdminId: job.jobAdminId,
    };
    updateEvent(updatedAppointment);
    addAppointmentNotesToJobNotes(job.jobAdminId, updatedAppointment.notes);
    setFieldValue("jobAdminId", job.jobAdminId);
    resetForm({ values: updatedAppointment });
    setIsJobModalOpen(false);
  };

  const handleOpenJobFolder = (id) => {
    sessionStorage.setItem("active-job", id);
    setFolder({
      ...folder,
      jobId: id,
      open: true,
      folderIndex: folderRouteButtons[0].tab,
    });
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={
          createExistingEvent(appointmentDetails.id) || createBlankAppointment()
        }
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          if (appointmentDetails?.id) {
            handleUpdateAppointment(values, actions);
          } else {
            handleCreateAppointment(values);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          isValid,
          dirty,
          setFieldValue,
          resetForm,
          submitForm,
        }) => (
          <>
            <DialogContent
              sx={{ p: 0, mt: 1, minHeight: "600px", maxHeight: "600px" }}
            >
              <Box sx={{ p: 0, overflow: "auto" }}>
                <Form height={"100%"} width={"100%"}>
                  <Grid
                    container
                    xs={12}
                    alignItems={"stretch"}
                    justifyContent={"space-evenly"}
                  >
                    {/* Customer Information Form */}
                    <HeaderFooterCard
                      title="Customer Information"
                      secondary={
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.clientReminder}
                              onChange={(e) =>
                                setFieldValue(
                                  "clientReminder",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Enable Customer Reminder"
                        />
                      }
                      component={Grid}
                      container
                      item
                      height={"100%"}
                      content={
                        <Grid
                          container
                          px={1}
                          spacing={4.35}
                          alignItems="stretch"
                        >
                          <Grid item xs={12}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name="clientName"
                              label="Client Name"
                              value={values.clientName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.clientName && Boolean(errors.clientName)
                              }
                              helperText={
                                touched.clientName && errors.clientName
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InputMask
                              mask="(999) 999-9999"
                              value={values.clientCellular}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  variant="standard"
                                  fullWidth
                                  name="clientCellular"
                                  label="Client Cellular"
                                  error={
                                    touched.clientCellular &&
                                    Boolean(errors.clientCellular)
                                  }
                                  helperText={
                                    touched.clientCellular &&
                                    errors.clientCellular
                                  }
                                  required
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name="clientEmail"
                              label="Email"
                              value={values.clientEmail}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.clientEmail &&
                                Boolean(errors.clientEmail)
                              }
                              helperText={
                                touched.clientEmail && errors.clientEmail
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name="year"
                              label="Year"
                              value={values.year}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.year && Boolean(errors.year)}
                              helperText={touched.year && errors.year}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name="make"
                              label="Make"
                              value={values.make}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.make && Boolean(errors.make)}
                              helperText={touched.make && errors.make}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name="model"
                              label="Model"
                              value={values.model}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.model && Boolean(errors.model)}
                              helperText={touched.model && errors.model}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name="color"
                              label="Color"
                              value={values.color}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.color && Boolean(errors.color)}
                              helperText={touched.color && errors.color}
                            />
                          </Grid>
                          <Grid item xs={12} pb={2.75}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              name="description"
                              label="Description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <HeaderFooterCard
                      title="Appointment Information"
                      secondary={
                        <>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.allDay}
                                onChange={(e) =>
                                  setFieldValue("allDay", e.target.checked)
                                }
                              />
                            }
                            label="All Day"
                          />
                        </>
                      }
                      component={Grid}
                      container
                      item
                      content={
                        <Grid
                          container
                          px={1}
                          spacing={4.35}
                          alignItems="stretch"
                        >
                          <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth required>
                              <InputLabel id="apptCategoryId-label">
                                Appointment Type
                              </InputLabel>
                              <Select
                                labelId="apptCategoryId-label"
                                name="apptCategoryId"
                                value={values.apptCategoryId}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue(
                                    "apptCategoryId",
                                    e.target.value
                                  );

                                  const selectedType = appointmentTypes.find(
                                    (type) =>
                                      type.apptCategoryId === e.target.value
                                  );
                                  if (selectedType) {
                                    setHeaderColor(selectedType.color);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.apptCategoryId &&
                                  Boolean(errors.apptCategoryId)
                                }
                                label="Appointment Type"
                              >
                                {appointmentTypes.map((type) => (
                                  <MenuItem
                                    key={type.apptCategoryId}
                                    value={type.apptCategoryId}
                                  >
                                    {type.type}
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.apptCategoryId &&
                                errors.apptCategoryId && (
                                  <div style={{ color: "red" }}>
                                    {errors.apptCategoryId}
                                  </div>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                label="Start Date and Time"
                                value={values.startDateTime}
                                maxDateTime={moment(values.endDateTime).subtract(
                                  30,
                                  "minutes"
                                )}
                                onChange={(date) =>
                                  setFieldValue("startDateTime", date)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="standard"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                label="End Date and Time"
                                value={values.endDateTime}
                                minDateTime={moment(values.startDateTime).add(
                                  30,
                                  "minutes"
                                )}
                                shouldDisableDate={(date) => {
                                  const startPlusBuffer = new Date(
                                    values.startDateTime
                                  );
                                  return moment(date).isBefore(
                                    startPlusBuffer,
                                    "D"
                                  );
                                }}
                                shouldDisableTime={(timeValue, view) => {
                                  const startDate = new Date(
                                    values.startDateTime
                                  );
                                  const endDate = new Date(timeValue);
                                  const startPlusBuffer = new Date(
                                    new Date(startDate).getTime() + 30 * 60000
                                  );

                                  if (view === "hours" || view === "minutes") {
                                    const isSameDay =
                                      endDate.getFullYear() ===
                                        startDate.getFullYear() &&
                                      endDate.getMonth() ===
                                        startDate.getMonth() &&
                                      endDate.getDate() === startDate.getDate();

                                    if (isSameDay) {
                                      return (
                                        endDate.getTime() >
                                        startPlusBuffer.getTime()
                                      );
                                    }
                                  }
                                  return false;
                                }}
                                onChange={(date) =>
                                  setFieldValue("endDateTime", date)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="standard"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                const currentDateTime = moment().format(
                                  "YYYY-MM-DD HH:mm:ss"
                                );
                                const { selectionStart, selectionEnd } =
                                  notesInputRef.current;
                                const newValue =
                                  values.notes.slice(0, selectionStart) +
                                  currentDateTime +
                                  values.notes.slice(selectionEnd);

                                setFieldValue("notes", newValue);
                              }}
                            >
                              Insert Timestamp Into Notes
                            </Button>
                          </Grid>
                          <Grid item xs={12} pb={3.35}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={8}
                              name="notes"
                              label="Notes"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputRef={notesInputRef} // Reference for the notes input field
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                </Form>
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end", mt: 2, px: 2 }}>
              {appointmentDetails?.id ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={submitForm}
                    disabled={!isValid || !dirty}
                  >
                    Update Appointment
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setDeleteDialogOpen(true)}
                  >
                    Delete Appointment
                  </Button>
                  {!values.jobAdminId && (
                    <Button
                      variant="outlined"
                      onClick={() => handleConvertToJob(values)}
                    >
                      Convert to Job
                    </Button>
                  )}
                  {values.jobAdminId && (
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenJobFolder(values.jobAdminId)}
                    >
                      Open Job Folder
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  variant="outlined"
                  onClick={submitForm}
                  disabled={!isValid || !dirty}
                >
                  Create Appointment
                </Button>
              )}
              <Button variant="outlined" onClick={onClose}>
                {appointmentDetails?.id ? "Close" : "Cancel"}
              </Button>
            </DialogActions>
            <AddJobModal
              open={isJobModalOpen}
              onOpen={setIsJobModalOpen}
              onClose={() => setIsJobModalOpen(false)}
              onAdded={(job) => handleJobCreated(job, setFieldValue, resetForm)}
              initialValues={initialJobValues}
            />
          </>
        )}
      </Formik>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteAppointment} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentsFolder;
