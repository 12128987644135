import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SettingsPage from 'views/pages/setttings-page';
import ContactsSettings from 'views/pages/setttings-page/settings-contacts';
import UserSettings from 'views/pages/setttings-page/settings-contacts/Users';
import GroupSettings from 'views/pages/setttings-page/settings-contacts/Groups';
import AssigneesSettings from 'views/pages/setttings-page/settings-contacts/Assignees';
import Scheduling from 'views/pages/setttings-page/settings-scheduling';
import ProductionSettings from 'views/pages/setttings-page/settings-production';
import LocationSettings from 'views/pages/setttings-page/settings-location';
import InformationSettings from 'views/pages/setttings-page/settings-location/Information';
import PreferencesSettings from 'views/pages/setttings-page/settings-location/Preferences';
import AppointmentScheduleSettings from 'views/pages/setttings-page/settings-scheduling/AppointmentSchedule';
import JobClassSettings from 'views/pages/setttings-page/settings-scheduling/JobClasses';
import ProductionScheduleSettings from 'views/pages/setttings-page/settings-scheduling/ProductionSchedule';
import DashboardPage from 'views/pages/dashboard-page/index.js';
import DepartmentSettings from 'views/pages/setttings-page/settings-production/Departments';
import GoalsSettings from 'views/pages/setttings-page/settings-production/GoalsSettings';
import JobsSettings from 'views/pages/setttings-page/settings-jobs';
import BadgesSettings from 'views/pages/setttings-page/settings-jobs/Badges';
import TodoSettings from 'views/pages/setttings-page/settings-jobs/ToDo';
import NotesCategoriesSettings from 'views/pages/setttings-page/settings-jobs/NotesCategories';
import DelayReasonsSettings from 'views/pages/setttings-page/settings-jobs/DelayReasons';
import MediaCategoriesSettings from 'views/pages/setttings-page/settings-jobs/MediaCategories';
import LeaderBoard from 'views/pages/leaderboard';
import ReportsPage from 'views/pages/reports-page';
import TerminalPage from 'views/pages/terminal-page';
import MessageView from 'views/pages/messaging';
import MessagingSettingsContainer from 'views/pages/setttings-page/settings-messaging';
import MessagingPresets from 'views/pages/setttings-page/settings-messaging/Presets';
import ModuleSettings from 'views/pages/setttings-page/settings-location/Modules';
import AppointmentsView from 'views/pages/appointments-page';
import AppointmentSettingsContainer from 'views/pages/setttings-page/settings-appointments';
import AppointmentTypes from 'views/pages/setttings-page/settings-appointments/AppointmentTypes';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/pages/sample-page')));
const SchedulePage = Loadable(lazy(() => import('views/pages/schedule-page')));
const ProductionPage = Loadable(lazy(() => import('views/pages/production-page')));
const JobFolder = Loadable(lazy(() => import('views/pages/jobfolder-page')));

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    // {
    //   path: "/",
    //   element: <SamplePage />,
    // },
    // {
    //   path: "/sample-page",
    //   element: <SamplePage />,
    // },
    {
      path: "/production/*",
      element: <ProductionPage />,
    },
    {
      path: "/appointments/*",
      element: <AppointmentsView />,
    },
    {
      path: "/schedule/*",
      element: (
        <AuthGuard>
          <SchedulePage />
        </AuthGuard>
      ),
      children: [
        {
          path: "job",
          element: <JobFolder />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <DashboardPage />,
    },
    {
      path: "/reports",
      element: <ReportsPage />,
    },
    {
      path: "/terminal",
      element: <TerminalPage />,
    },
    // {
    //   path: "/messages",
    //   element: <MessageView />,
    // },
    {
      path: "/leaderboard",
      element: <LeaderBoard />,
    },
    {
      path: "/settings/*",
      element: <SettingsPage />,

      children: [
        {
          path: "location",
          element: <LocationSettings />,
          children: [
            {
              path: "information",
              element: <InformationSettings />,
            },
            {
              path: "preferences",
              element: <PreferencesSettings />,
            },
            {
              path: "modules",
              element: <ModuleSettings />,
            },
          ],
        },
        {
          path: "contacts/*",
          element: <ContactsSettings />,
          children: [
            {
              path: "users",
              element: <UserSettings />,
            },
            {
              path: "groups",
              element: <GroupSettings />,
            },
            {
              path: "assignees",
              element: <AssigneesSettings />,
            },
          ],
        },
        {
          path: "appointments/*",
          element: <AppointmentSettingsContainer />,
          children: [
            {
              path: "appointmenttypes",
              element: <AppointmentTypes />,
            },
          ],
        },
        {
          path: "scheduling/*",
          element: <Scheduling />,
          children: [
            {
              path: "jobclass",
              element: <JobClassSettings />,
            },
            {
              path: "productionschedule",
              element: <ProductionScheduleSettings />,
            },
          ],
        },
        {
          path: "productiondepts/*",
          element: <ProductionSettings />,
          children: [
            {
              path: "info",
              element: <DepartmentSettings />,
            },
            {
              path: "goals",
              element: <GoalsSettings />,
            },
          ],
        },
        {
          path: "jobs/*",
          element: <JobsSettings />,
          children: [
            {
              path: "badges",
              element: <BadgesSettings />,
            },
            {
              path: "todos",
              element: <TodoSettings />,
            },
            {
              path: "notescategories",
              element: <NotesCategoriesSettings />,
            },
            {
              path: "mediacategories",
              element: <MediaCategoriesSettings />,
            },
            {
              path: "delayreasons",
              element: <DelayReasonsSettings />,
            },
          ],
        },
        {
          path: "messaging/*",
          element: <MessagingSettingsContainer />,
          children: [
            {
              path: "presets",
              element: <MessagingPresets />,
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
