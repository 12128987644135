import "devextreme/dist/css/dx.dark.compact.css";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterRow,
  GroupItem,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useState } from "react";
import {
  chooserColumn,
  sumHours,
  sumTotal,
} from "../reportLogic";
import {
  arrived,
  customer,
  jobId,
  hours,
  description,
  groupName,
  jobClass,
  phone,
  scheduledIn,
  total,
  vehicle,
  year,
  make,
  model,
  vin,
  repairStart,
  datePickup,
  dateReady,
  scheduledOut,
  leadTime,
  lagTime,
  productionCycle,
  touchTime,
  color,
} from "../reportColumns";

const columns = [
  jobId,
  arrived,
  scheduledIn,
  chooserColumn(repairStart),
  chooserColumn(datePickup),
  chooserColumn(dateReady),
  chooserColumn(scheduledOut),
  chooserColumn(leadTime),
  chooserColumn(lagTime),
  chooserColumn(productionCycle),
  chooserColumn(touchTime),
  phone,
  customer,
  chooserColumn(description),
  vehicle,
  chooserColumn(color),
  chooserColumn(year),
  chooserColumn(make),
  chooserColumn(model),
  chooserColumn(vin),
  chooserColumn(groupName),
  hours,
  jobClass,
  total,
];

const InboundVehicle = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();

  const calculateSelectedRow = (options) => {
    if (options.name === 'ArrivedCount') {
      getTotalArrivedCount(options)
    }
  }

  const getTotalArrivedCount = (options) => {
    if(options.summaryProcess === "start"){
      options.totalValue = 0;
    } else if (options.summaryProcess === "calculate") {
      let total = 0
      data.forEach(element => {
          if(element.dateTowIn !== null){
            total++
          }
      });
      options.totalValue = total
    }
  }

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            vehicle: `${d.year} ${d.color} ${d.make} ${d.model}`,
            jobId: d.hat !== "" ? d.hat : d.jobAdminInt
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      {!!data ? (
        <DataGrid
          height="100%"
          dataSource={data}
          keyExpr="jobId"
          columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          rowAlternationEnabled={true}
        >
          <Scrolling mode="infinite" />
          <FilterRow visible />
          <SearchPanel visible />
          <ColumnChooser enabled={true} />
          <GroupPanel visible={true} />
          <Summary calculateCustomSummary={calculateSelectedRow}>
            <TotalItem
              column="jobId"
              summaryType="count"
              displayFormat="Job Count: {0}"
            />
            <TotalItem
              column="hours"
              summaryType="sum"
              customizeText={sumHours}
            />
            <TotalItem
              name="ArrivedCount"
              summaryType="custom"
              showInColumn="dateTowIn"
              displayFormat="Arrived: {0}"
              // customizeText={sumHours}
            />
            <TotalItem
              column="subTotal"
              summaryType="sum"
              customizeText={sumTotal}
            />
            <GroupItem
              column="jobId"
              summaryType="count"
              displayFormat="Job Count: {0}"
            />
            <GroupItem
              column="hours"
              summaryType="sum"
              displayFormat="Group Hours: {0}"
              customizeText={sumHours}
            />
            <GroupItem
              name="ArrivedCount"
              summaryType="custom"
              showInGroupFooter={false}
              alignByColumn={true}
              displayFormat="Arrived: {0}"
            />
            <GroupItem
              column="subTotal"
              summaryType="sum"
              displayFormat="Group Subtotal: {0}"
              customizeText={sumTotal}
            />
          </Summary>
          {columns.map((c) => (
            <Column key={c.dataField} {...c} />
          ))}
        </DataGrid>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default InboundVehicle;
