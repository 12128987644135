const { formatPhoneNumber } = require("helpers/format");

const arrived = {
  dataField: "dateTowIn",
  alignment: "left",
  caption: "Arrival Date",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const assignee = {
  dataField: "assignee",
  alignment: "left",
  caption: "Assignee",
};

const avgSeverity = {
  dataField: "avgSeverity",
  alignment: "right",
  caption: "Average Severity",
  format: {
    type: "currency",
    precision: 2,
  },
};

const color = {
  dataField: "color",
  alignment: "left",
  caption: "Color",
};

const customer = {
  dataField: "clientName",
  alignment: "left",
  caption: "Customer",
};

const cycleGoal = {
  dataField: "cycleGoal",
  alignment: "left",
  caption: "Goal",
};

const cycleTime = {
  dataField: "cycleTime",
  alignment: "right",
  caption: "Cycle Time",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const cycleTimeAvg = {
  dataField: "cycleTime",
  alignment: "right",
  caption: "Cycle Time",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const datePickup = {
  dataField: "datePickup",
  alignment: "left",
  caption: "Delivered Date",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const dateReady = {
  dataField: "dateReady",
  alignment: "left",
  caption: "Completed Date",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const delivered = {
  dataField: "datePickup",
  alignment: "left",
  caption: "Delivered Date",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const deliveredCount = {
  dataField: "deliveredCount",
  alignment: "left",
  caption: "Delivered Count",
};

const department = {
  dataField: "departmentName",
  alignment: "left",
  caption: "Department",
};

const deptDateTimeIn = {
  dataField: "dateTimeIn",
  alignment: "left",
  caption: "Entered Department",
  dataType: "date",
};

const deptDateTimeOut = {
  dataField: "dateTimeOut",
  alignment: "left",
  caption: "Left Department",
  dataType: "date",
};

const description = {
  dataField: "description",
  alignment: "left",
  caption: "Job Description",
};

const groupName = {
  dataField: "groupName",
  alignment: "left",
  caption: "Group",
};

const email = {
  dataField: "clientEmail",
  alignment: "left",
  caption: "Email",
};

const efficiency = {
  dataField: "efficiency",
  alignment: "right",
  caption: "Efficiency",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const hat = {
  dataField: "hat",
  alignment: "left",
  caption: "Job Id",
};

const hours = {
  dataField: "hours",
  alignment: "right",
  caption: "Hours",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const inventoryCount = {
  dataField: "inventoryCount",
  alignment: "right",
  caption: "Inventory Count",
};

const jobId = {
  dataField: "jobId",
  alignment: "left",
  caption: "Job Id",
};

const jobClass = {
  dataField: "jobClassName",
  alignment: "left",
  caption: "Job Class",
};

const k2kCycle = {
  dataField: "k2kCycle",
  alignment: "right",
  caption: "Overall Cycle",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const lagCycle = {
  dataField: "lagCycle",
  alignment: "right",
  caption: "Lag Cycle",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const lagTime = {
  dataField: "lagTime",
  alignment: "right",
  caption: "Lag Time",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const leadCycle = {
  dataField: "leadCycle",
  alignment: "right",
  caption: "Lead Cycle",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const leadTime = {
  dataField: "leadTime",
  alignment: "right",
  caption: "Lead Time",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const locationName = {
  dataField: "locationName",
  alignment: "left",
  caption: "Location Name",
};

const make = {
  dataField: "make",
  alignment: "left",
  caption: "Make",
};

const model = {
  dataField: "model",
  alignment: "left",
  caption: "Model",
};

const phone = {
  dataField: "clientCellular",
  alignment: "left",
  caption: "Phone",
  calculateDisplayValue: (row) => formatPhoneNumber(row.clientCellular),
};

const productionCycle = {
  dataField: "productionCycle",
  alignment: "right",
  caption: "Production Cycle",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const productionTouchTime = {
  dataField: "productionTouchTime",
  alignment: "right",
  caption: "Production Touch Time",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const repairStart = {
  dataField: "repairStart",
  alignment: "left",
  caption: "Start Date",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const scheduledIn = {
  dataField: "scheduledIn",
  alignment: "left",
  caption: "Scheduled In",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const scheduledOut = {
  dataField: "scheduledOut",
  alignment: "left",
  caption: "Scheduled Out",
  dataType: "date",
  format: {
    type: "shortDate",
  },
};

const touchTime = {
  dataField: "touchTime",
  alignment: "right",
  caption: "Touch Time",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const total = {
  dataField: "subTotal",
  alignment: "right",
  caption: "Total",
  format: {
    type: "currency",
    precision: 2,
  },
};

const totalHours = {
  dataField: "totalHours",
  alignment: "right",
  caption: "Total Hours",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const totalRefinish = {
  dataField: "totalRefinish",
  alignment: "right",
  caption: "Total Refinish Hours",
  format: {
    type: "fixedPoint",
    precision: 2,
  },
};

const vehicle = {
  dataField: "vehicle",
  alignment: "left",
  caption: "Vehicle",
};

const vin = {
  dataField: "vin",
  alignment: "left",
  caption: "VIN",
};

const wipCount = {
  dataField: "wipCount",
  alignment: "right",
  caption: "WIP Count",
};

const year = {
  dataField: "year",
  alignment: "left",
  caption: "Year",
};

export {
  arrived,
  assignee,
  avgSeverity,
  color,
  customer,
  cycleGoal,
  cycleTime,
  cycleTimeAvg,
  datePickup,
  dateReady,
  delivered,
  deliveredCount,
  department,
  deptDateTimeIn,
  deptDateTimeOut,
  description,
  email,
  efficiency,
  hat,
  hours,
  inventoryCount,
  groupName,
  jobId,
  jobClass,
  k2kCycle,
  lagCycle,
  lagTime,
  leadCycle,
  leadTime,
  locationName,
  make,
  model,
  phone,
  productionCycle,
  productionTouchTime,
  repairStart,
  scheduledIn,
  scheduledOut,
  total,
  totalHours,
  totalRefinish,
  touchTime,
  vehicle,
  vin,
  wipCount,
  year,
};
