import useAuth from "hooks/useAuth";
import { ListItemButton, List, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import useSettings from "hooks/useSettings";

function LocationDropDown({ setOpen }) {
  const theme = useTheme();
  const auth = useAuth();
  const { user, isLoading, isSelected } = auth;
  const navigate = useNavigate();
  const location = useLocation();
  const { setBlockerAction } = useSettings();

  const switchLocation = (shortName) => {
    auth.selectLocation(shortName);
  };

  const handleClick = (event, shortName) => {
    setOpen(false);
    if (location.pathname.includes("settings")) {
      setBlockerAction({ func: switchLocation, param: shortName });
      navigate(`${location.pathname}?${shortName}`);
    } else {
      switchLocation(shortName);
    }
  };

  if (isLoading) {
    return <h6>...</h6>;
  }

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        maxHeight: "calc(100vh - 96px)",
        p: 1,
      }}
    >
      <List
        component="nav"
        sx={{
          width: "100%",
          minWidth: "content",
          maxWidth: "100%",
          p: 0,
        }}
        disabled={isLoading}
      >
        {user.locations
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((item, index) => (
            <ListItemButton
              value={item.shortName}
              selected={isSelected.shortName === item.shortName}
              onClick={(e) => handleClick(e, item.shortName)}
              key={`${index}-select-${item.shortName}-${isSelected}`}
            >
              {item.name}
            </ListItemButton>
          ))}
      </List>
    </Box>
  );
}

export default LocationDropDown;
