import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useBadges from "hooks/useBadges";
import useSettings from "hooks/useSettings";
import IconPicker from "hdc-components/IconPicker";
import SettingsCard from "../../SettingsCard"; 
import BadgeRows from "./Rows";

const defaultGuid = "00000000-0000-0000-0000-000000000000";
const newRow = {
  badgeId: defaultGuid,
  description: "",
  iconName: "",
  isActive: true,
  badgeStates: [
    {
      badgeStateId: defaultGuid,
      state: 1,
      description: "Inactive",
      color: "#757575",
      badgeNotifications: [],
    },
    {
      badgeStateId: defaultGuid,
      state: 2,
      description: "In Process",
      color: "#1E88E5",
      badgeNotifications: [],
    },
    {
      badgeStateId: defaultGuid,
      state: 3,
      description: "Alert",
      color: "#FFB300",
      badgeNotifications: [],
    },
    {
      badgeStateId: defaultGuid,
      state: 4,
      description: "Critical",
      color: "#E53935",
      badgeNotifications: [],
    },
    {
      badgeStateId: defaultGuid,
      state: 5,
      description: "Complete",
      color: "#4CAF50",
      badgeNotifications: [],
    },
  ],
};

const BadgesSettings = () => {
    const [ initReady, setInitReady ] = useState(false);
    const [ openIconPicker, setOpenIconPicker ] = useState(false);
    const { selectedLocation } = useAuth();
    const { getBadges } = useBadges();
    const [assignees, setAssignees] = useState([])
    const {
        uiSettingData, 
        setOriginSettingData, 
        setUISettingData, 
        hasChangesSettingData, 
        defaultSettingGuid
    } = useSettings();

    useEffect(() => {
        setUISettingData([]);
        setInitReady(true);
    },[]);

    useEffect(() => {
        API.getData("badges").then((response) => {
            setOriginSettingData(structuredClone(response));
            setUISettingData(structuredClone(response));
        });
    }, [selectedLocation]);

        useEffect(() => {
          API.getData("contacts").then((response) => {
            setAssignees(structuredClone(response));
          });
        }, [selectedLocation]);

    const resetIfNewItem = (items) => {
        items.forEach((item) => {
            if (item.badgeId.startsWith(defaultSettingGuid)) {
                item.badgeId = defaultSettingGuid;
            }
        });
        return items;
    };

    const save = (callback) => {
        API.putData("badges", resetIfNewItem(uiSettingData)).then((response) => {
            if(response){
                setOriginSettingData(structuredClone(response));
                setUISettingData(structuredClone(response));
                getBadges();
            }
            if(typeof(callback) == "function") callback();
        });
    };

    const addTableRow = () => {
        setOpenIconPicker(true);
    };

    const handleSelectIcon = (iconName) => {   
        const row = structuredClone(newRow);
        row.iconName = iconName;
        row.badgeId = row.badgeId + Date.now();
        setUISettingData([...uiSettingData, row]);
        setOpenIconPicker(false);
    };    

    const handleCloseIconPicker = () => {
        setOpenIconPicker(false);
    };

    const enableSave = () => {
        const invalid = uiSettingData?.some(d => d.description.trim() == "");
        return hasChangesSettingData && !invalid;
    };

    const columns = [
        {name:'Icon', width:'15%'},
        {name:'Badge', width:'40%'},
        {name:'States', width:'15%'},
        {name:'Active', width:'15%'},
        {name:'Delete', width:'15%'}
    ];

    return (
        !!initReady &&             
        <>
            <SettingsCard
                title="Badges"
                columns={columns}
                addButtonTooltip="Add Badge"
                handleAddRow={addTableRow}
                handleSave={save}
                enableSave={enableSave()}
                >
                <BadgeRows
                    data={uiSettingData}
                    columns={columns}
                    assignees={assignees}
                />
            </SettingsCard>
            
            <IconPicker
                open={openIconPicker}
                handleSubmit={handleSelectIcon}
                handleCancel={handleCloseIconPicker}
                contentSx={{width:"648px", height:"550px"}}
            />
        </>
    );
};

export default BadgesSettings;
  