import "devextreme/dist/css/dx.dark.compact.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid } from "devextreme-react";
import {
  Column,
  FilterRow,
  GroupPanel,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  locationName,
  deliveredCount,
  total,
  avgSeverity,
  totalHours,
  totalRefinish,
  wipCount,
  inventoryCount,
  leadCycle,
  productionCycle,
  lagCycle,
  k2kCycle,
  productionTouchTime,
  touchTime,
} from "../reportColumns";
import { Button, Grid, IconButton, Pagination, Switch, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import HeaderFooterCard from "hdc-components/HeaderFooterCard";
import ReportRow from "./ReportRow";
import { Print } from "@mui/icons-material";

const columns = [
  locationName,
  deliveredCount,
  total,
  avgSeverity,
  totalHours,
  totalRefinish,
  wipCount,
  inventoryCount,
  leadCycle,
  productionCycle,
  lagCycle,
  k2kCycle,
  productionTouchTime,
  touchTime,
];

const MSOReportCard = forwardRef((props, ref) => {
  const { chartType, reportData } = props;
  const [data, setData] = useState();
  const [view, setView] = useState("list")

  const toggleView = () => {
    if(view === "list"){
      setView("reportCard")
    } else {
      setView("list")
    }
  }

  const roundToHundredth = (value) => {
    return Math.round(value * 100) / 100;
  };  

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (!!reportData) {
      setData(
        reportData.data.map((d) => {
          return {
            ...d,
            locationName: d.alias !== "" ? d.alias : d.locationName,
          };
        })
      );
    }
  }, [reportData]);

  return (
    <>
      <Toolbar
        variant="dense"
        sx={{
          alignContent: "center",
          justifyContent: "space-between",
          background: "#202020",
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "#fff" }}>
            {view === "list" ? "List View" : "Report Card"}
          </Typography>
          <Switch checked={view === "reportCard"} onChange={toggleView} />
        </Box>
      </Toolbar>
      {!!data ? (
        <>
          {view === "list" && (
            <DataGrid
              height="100%"
              dataSource={data}
              keyExpr="locationName"
              columnAutoWidth={true}
              showColumnLines={false}
              showRowLines={false}
              showBorders={false}
              rowAlternationEnabled={true}
            >
              <Scrolling mode="infinite" />
              <FilterRow visible />
              <SearchPanel visible />
              <GroupPanel visible={true} />
              {columns.map((c) => (
                <Column key={c.dataField} {...c} />
              ))}
            </DataGrid>
          )}
          {view === "reportCard" && (
            <Box
              sx={{
                height: "calc(100vh - 190px - 61px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                {data.slice(currentPage - 1, currentPage).map((l, index) =>
                (
                  <Grid
                    key={index}
                    container
                    item
                    xs={12}
                    alignContent="center"
                    justifyContent="center"
                    pb={1}
                  >
                    <HeaderFooterCard
                      title={
                        <Typography variant="h2">
                          {`${l.locationName} Report Card`}
                        </Typography>
                      }
                      // for Print function
                      // secondary={
                      //   <IconButton onClick={() => handlePrint(cardRef)}>
                      //     <Print />
                      //   </IconButton>
                      // }
                      component={Grid}
                      xs={12}
                      container
                      item
                      content={
                        <Grid container pb={2}>
                          <ReportRow
                            title="Delivered Job Count"
                            value={l.deliveredCount}
                          />
                          <ReportRow title="Total" value={`$${l.subTotal}`} />
                          <ReportRow
                            title="Average Severity"
                            value={`$${l.avgSeverity ?? "---"}`}
                            pb={2.5}
                          />
                          <ReportRow
                            title="Total Hours"
                            value={`${l.totalHours} Hrs`}
                          />
                          <ReportRow
                            title="Total Refinish Hours"
                            value={`${l.totalRefinish} Hrs`}
                            pb={2.5}
                          />
                          <ReportRow title="WIP Count" value={l.wipCount} />
                          <ReportRow
                            title="Inventory Count"
                            value={l.inventoryCount}
                          />
                          <ReportRow
                            title="Lead Cycle"
                            value={`${roundToHundredth(l.leadCycle)} Days`}
                          />
                          <ReportRow
                            title="Production Cycle"
                            value={`${roundToHundredth(
                              l.productionCycle
                            )} Days`}
                          />
                          <ReportRow
                            title="Lag Cycle"
                            value={`${roundToHundredth(l.lagCycle)} Days`}
                          />
                          <ReportRow
                            title="Overall Cycle"
                            value={`${roundToHundredth(l.k2kCycle)} Days`}
                            pb={2.5}
                          />
                          <ReportRow
                            title="Production Touch Time"
                            value={`${roundToHundredth(
                              l.productionTouchTime
                            )} Hr/Day`}
                            pb={0}
                          />
                          <ReportRow
                            title="Overall Touch Time"
                            value={`${roundToHundredth(l.touchTime)} Hr/Day`}
                          />
                        </Grid>
                      }
                    />
                  </Grid>
                ))}
              </Box>
              <Pagination
                count={Math.ceil(data.length / 1)}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
                sx={{ mt: 2 }}
              />
            </Box>
          )}
        </>
      ) : (
        <div> No Data to Display </div>
      )}
    </>
  );
});

export default MSOReportCard;
