// third-party
import { FormattedMessage } from "react-intl";
import {
  IconCalendar,
  IconCalendarEvent,
  IconSitemap,
  IconLayoutKanban,
  IconDashboard,
  IconSettings,
  IconMessages,
  IconReport,
  IconPlane,
  IconTrophy
} from "@tabler/icons";

const icons = {
  IconSitemap,
  IconCalendar,
  IconCalendarEvent,
  IconLayoutKanban,
  IconDashboard,
  IconReport,
  IconSettings,
  IconPlane,
  IconMessages,
  IconTrophy
};

const mobileAppRoutes = {
  id: "mobile-app-routes",
  type: "group",
  children: [
    {
      id: "production",
      title: <FormattedMessage id="production" />,
      type: "item",
      url: "/production",
      icon: icons.IconLayoutKanban,
    },
    {
      id: "schedule",
      title: <FormattedMessage id="schedule" />,
      type: "item",
      url: "/schedule",
      icon: icons.IconCalendar,
    },
    // {
    //   id: "messaging",
    //   title: <FormattedMessage id="messages" />,
    //   type: "item",
    //   url: "/messages",
    //   icon: icons.IconMessages,
    // },
  ],
};

const appRoutes = {
  id: "app-routes",
  type: "group",
  children: [
    {
      id: "production",
      title: <FormattedMessage id="production" />,
      type: "item",
      url: "/production",
      icon: icons.IconLayoutKanban,
    },
    {
      id: "appointments",
      title: <FormattedMessage id="appointments" />,
      type: "item",
      url: "/appointments",
      icon: icons.IconCalendarEvent,
    },
    {
      id: "schedule",
      title: <FormattedMessage id="schedule" />,
      type: "item",
      url: "/schedule",
      icon: icons.IconCalendar,
    },
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
    },
    {
      id: "reports",
      title: <FormattedMessage id="reports" />,
      type: "item",
      url: "/reports",
      icon: icons.IconReport,
    },
    // {
    //   id: "messaging",
    //   title: <FormattedMessage id="messaging" />,
    //   type: "item",
    //   url: "/messages",
    //   icon: icons.IconMessages,
    // },
    {
      id: "leaderboard",
      title: <FormattedMessage id="leaderboard" />,
      type: "item",
      url: "/leaderboard",
      icon: icons.IconTrophy,
    },
    {
      id: "terminal",
      title: <FormattedMessage id="terminal" />,
      type: "item",
      url: "/terminal",
      icon: icons.IconPlane,
    },
    {
      id: "location-settings",
      title: <FormattedMessage id="location-settings" />,
      type: "collapse",
      icon: icons.IconSettings,
      url: "/settings",
      children: [
        {
          id: "location",
          title: (
            <>
              <FormattedMessage id="general" />
            </>
          ),
          type: "collapse",
          url: "/settings/location",
          children: [
            {
              id: "information",
              title: (
                <>
                  <FormattedMessage id="information" />
                </>
              ),
              type: "item",
              url: "/settings/location/information",
              default: true,
            },
            {
              id: "preferences",
              title: (
                <>
                  <FormattedMessage id="preferences" />
                </>
              ),
              type: "item",
              url: "/settings/location/preferences",
            },
            {
              id: "modules",
              title: (
                <>
                  <FormattedMessage id="Modules" />
                </>
              ),
              type: "item",
              url: "/settings/location/modules",
            },
          ],
        },
        {
          id: "contacts",
          title: (
            <>
              <FormattedMessage id="contacts" />
            </>
          ),
          type: "collapse",
          url: "/settings/contacts",
          children: [
            {
              id: "users",
              title: (
                <>
                  <FormattedMessage id="users" />
                </>
              ),
              type: "item",
              url: "/settings/contacts/users",
              default: true,
            },
            {
              id: "groups",
              title: (
                <>
                  <FormattedMessage id="groups" />
                </>
              ),
              type: "item",
              url: "/settings/contacts/groups",
            },
            {
              id: "assignees",
              title: (
                <>
                  <FormattedMessage id="assignees" />
                </>
              ),
              type: "item",
              url: "/settings/contacts/assignees",
            },
          ],
        },
        {
          id: "appointments",
          title: (
            <>
              <FormattedMessage id="appointments" />
            </>
          ),
          type: "item",
          url: "/settings/appointments/appointmenttypes",
        },
        {
          id: "scheduling",
          title: (
            <>
              <FormattedMessage id="scheduling" />
            </>
          ),
          url: "/settings/scheduling",
          type: "collapse",
          children: [
            {
              id: "jobclass",
              title: (
                <>
                  <FormattedMessage id="jobclasses" />
                </>
              ),
              type: "item",
              url: "/settings/scheduling/jobclass",
              default: true,
            },
            {
              id: "productionschedule",
              title: (
                <>
                  <FormattedMessage id="productionschedule" />
                </>
              ),
              type: "item",
              url: "/settings/scheduling/productionschedule",
            },
          ],
        },
        {
          id: "production-departments",
          title: (
            <>
              <FormattedMessage id="production-departments" />
            </>
          ),
          type: "item",
          url: "/settings/productiondepts/info",
        },
        {
          id: "jobs",
          title: (
            <>
              <FormattedMessage id="jobs" />
            </>
          ),
          type: "collapse",
          url: "/settings/jobs",

          children: [
            {
              id: "badges",
              title: (
                <>
                  <FormattedMessage id="badges" />
                </>
              ),
              type: "item",
              url: "/settings/jobs/badges",
            },
            {
              id: "todos",
              title: (
                <>
                  <FormattedMessage id="todo-items" />
                </>
              ),
              type: "item",
              url: "/settings/jobs/todos",
            },

            {
              id: "mediacategories",
              title: (
                <>
                  <FormattedMessage id="media-categories" />
                </>
              ),
              type: "item",
              url: "/settings/jobs/mediacategories",
            },
            {
              id: "notescategories",
              title: (
                <>
                  <FormattedMessage id="notes-categories" />
                </>
              ),
              type: "item",
              url: "/settings/jobs/notescategories",
            },
            {
              id: "delayreasons",
              title: (
                <>
                  <FormattedMessage id="delay-reasons" />
                </>
              ),
              type: "item",
              url: "/settings/jobs/delayreasons",
            },
          ],
        },
        {
          id: "messaging",
          title: (
            <>
              <FormattedMessage id="messaging" />
            </>
          ),
          type: "item",
          url: "/settings/messaging/presets",
        },
      ],
    },
  ],
};

export {appRoutes, mobileAppRoutes};
