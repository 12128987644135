import * as React from "react";
import { Button, IconButton, TextField, Toolbar, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import { isSameMonth, getMonth, getYear, format } from "date-fns";
import { ArrowBack, ArrowBackIosNewRounded, ArrowBackIosRounded, ArrowForwardIosRounded, ArrowLeft, ArrowRight, KeyboardArrowLeftRounded, KeyboardArrowRightRounded, KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded } from "@mui/icons-material";
import JobClassLegend from "views/pages/schedule-page/jobclass-schedule/JobClassLegend";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Paper } from "@mui/material";
import moment from "moment";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

const CustomToolbar = (params) => {
  const [month, setMonth] = React.useState(
    new Date(params.componentsProps.actionBar.value).toLocaleDateString(
      "en-us",
      {
        month: "long",
      }
    )
  );
  const [year, setYear] = React.useState(
    getYear(new Date(params.componentsProps.actionBar.value))
  );
  const handleViewChange = (view) => {
    params.componentsProps.actionBar.setView(view);
  };
  const handlePriorWeek = () => {
    let newWeek =
      moment(params.componentsProps.actionBar.value);
      newWeek.subtract(1, 'week')
      let formatted = newWeek.format()
    params.componentsProps.actionBar.updateValue(new Date(formatted));
  };
  const handleNextWeek = () => {
    let newWeek = moment(params.componentsProps.actionBar.value);
    newWeek.add(1, "week");
    let formatted = newWeek.format();
    params.componentsProps.actionBar.updateValue(new Date(formatted));
  };
  const handlePriorMonth = () => {
    let newMonth =
      new Date(params.componentsProps.actionBar.value).getMonth() - 1;
    let newYear = year;
    if (newMonth < 0) {
      newMonth = 11;
      newYear = year - 1;
    }
    console.log("new year", newYear);
    console.log("new month", newMonth);
    let newDate = new Date(params.componentsProps.actionBar.value);
    if (year !== newYear) {
      newDate.setYear(newYear);
    }
    newDate.setMonth(newMonth);
    params.componentsProps.actionBar.updateValue(newDate);
  };
  const handleNextMonth = () => {
    let newMonth =
      new Date(params.componentsProps.actionBar.value).getMonth() + 1;
    let newYear = year;
    if (newMonth > 11) {
      newMonth = 0;
      newYear = year + 1;
    }
    console.log("new year", newYear);
    console.log("new month", newMonth);
    let newDate = new Date(params.componentsProps.actionBar.value);
    if (year !== newYear) {
      newDate.setYear(newYear);
    }
    newDate.setMonth(newMonth);
    params.componentsProps.actionBar.updateValue(newDate);
  };
  return (
    <Toolbar >
      <Tooltip title="Prior Month" placement="top">
        <IconButton onClick={() => handlePriorMonth()} size="sm">
          <KeyboardDoubleArrowLeftRounded />
        </IconButton>
      </Tooltip>
      <Tooltip title="Prior Week" placement="top">
        <IconButton onClick={() => handlePriorWeek()}>
          <KeyboardArrowLeftRounded />
        </IconButton>
      </Tooltip>
      <Button onClick={() => handleViewChange("month")}>{month}</Button>
      <Button onClick={() => handleViewChange("year")}>{year}</Button>
      <Tooltip title="Next Week" placement="top">
        <IconButton onClick={() => handleNextWeek()}>
          <KeyboardArrowRightRounded />
        </IconButton>
      </Tooltip>
      <Tooltip title="Next Month" placement="top">
        <IconButton onClick={() => handleNextMonth()}>
          <KeyboardDoubleArrowRightRounded />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const HighlightedPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export function CalendarHighlightWeek({
  label,
  value,
  setValue,
}) {
  const renderWeekPickerDay = (date, _selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(value);
    const end = endOfWeek(value);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);
    const isDisplayMonth =
      dayIsBetween || isFirstDay || (isLastDay && !isSameMonth(date, value));
    return (
      <HighlightedPickersDay
        {...pickersDayProps}
        disableMargin
        showDaysOutsideCurrentMonth={isDisplayMonth}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };
  const minDate = new Date("01-01-2020");

  const [view, setView] = React.useState("day");
  const updateValue = (value) => {
    setValue(value);
  };
  const [newDate, setNewDate] = React.useState('')
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label={label}
        value={value}
        minDate={minDate}
        onChange={(value) => {
          setNewDate(value)
          setView("day");
        }}
        onAccept={(value) => {
          const newYear = new Date(newDate).getFullYear();
          const year = new Date(value).getFullYear();
          const sameYear = newYear === year;
          setValue(sameYear ? value : newDate || value);
          setView("day");
          setNewDate("");
        }}
        view={view}
        showDaysOutsideCurrentMonth={true}
        renderDay={renderWeekPickerDay}
        showToolbar
        componentsProps={{
          actionBar: {
            value: new Date(value).toUTCString(),
            updateValue,
            view,
            setView,
          },
        }}
        ToolbarComponent={(params) => <CustomToolbar {...params} />}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="'Week of' MMM d"
      />
    </LocalizationProvider>
  );
}
