const { formatCurrency, getFloatValue } = require("helpers/format");

const sumByKey = (arr, key) => {
  let sum = 0;
  for (const obj of arr) {
    const currSum = obj[key] || 0;
    sum += currSum;
  }
  return sum;
};
const averageByKey = (arr, key) => {
  let sum = sumByKey(arr, key);
  return sum / arr.length;
};

const sumTotal = (data) => {
  return `Total: ${formatCurrency(data.value)}`;
};

const groupSumTotal = (data) => {
  return `Group ${sumTotal(data)}`;
};

const totalAvg = (data) => {
  return `Average Total: ${formatCurrency(data.value)}`;
};

const groupTotalAvg = (data) => {
  return `Group ${totalAvg(data)}`;
};

const sumHours = (data) => {
  return `Hours: ${getFloatValue(data.value)}`;
};

const groupSumHours = (data) => {
  return `Group ${sumHours(data)}`;
};

const cycleTimeAvg = (data) => {
  return `Cycle Time: ${getFloatValue(data.value)} Days`;
};
const touchTimeAvg = (data) => {
  return `Touch Time: ${getFloatValue(data.value)} Days`;
};

const chooserColumn = (column) => {
  return {...column, visible: false}
}

export {
  sumByKey,
  averageByKey,
  sumTotal,
  groupSumTotal,
  totalAvg,
  groupTotalAvg,
  sumHours,
  groupSumHours,
  cycleTimeAvg,
  touchTimeAvg,
  chooserColumn,
};
