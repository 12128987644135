import { AppointmentsFolderContext } from "contexts/AppointmentsFolderContext";
import { useContext } from "react";

const useAppointmentsFolder = () => {
  const context = useContext(AppointmentsFolderContext);

  if (!context) throw new Error("context must be used inside provider");
  return context;
};

export default useAppointmentsFolder;
