import { deepEqual } from "helpers";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import { publishItemIsDirty } from "services/PubSubPublisher";
import { usePubSub } from "./PubSubContext";
import usePermissions from "hooks/usePermissions";
import useAppointments from "hooks/useAppointments";

const blankAppointment = {
    "apptCategoryId": "",
    "startDateTime": "",
    "endDateTime": "",
    "allDay": false,
    "clientReminder": false,
    "description": "",
    "clientName": "",
    "clientCellular": "",
    "year": 0,
    "make": "string",
    "model": "string",
    "color": "string",
    "notes": "string",
    "appointmentId": "",
    "jobAdminId": "",
    "apptCategory": {
      "description": "",
      "displayColor": "",
      "isActive": true,
      "assignments": [],
      "apptCategoryId": ""
    }
  }

const initState = {
  AppointmentsFolder: {},
  appointmentId: "new",
  open: false,
  isLoaded: false,
  isModified: false,
  isAppointmentModified: false,
  isAssignmentsModified: false,
  isTodosModified: false,
  isAppointmentValid: true,
  folderIndex: 0,
};

const contextFunctions = {
  initContext: () => {},
  getAppointmentsFolder: () => {},
  setFolder: () => {},
  setLoaded: () => {},
  setOpen: () => {},
  createAppointment: () => {},
  updateAppointmentsFolder: () => {},
  revertFolder: () => {},
};
const initialState = {
  ...initState,
  ...contextFunctions,
};

const AppointmentsFolderContext = createContext();

const AppointmentsFolderProvider = ({ children }) => {
  const { client } = usePubSub();
  const { events } = useAppointments();
  const { selectedLocation } = useAuth();
  const { userPerms } = usePermissions();
  const [folder, setFolder] = useState(initialState);
  const [originalFolder, setOriginalFolder] = useState(initialState);
  const [shouldUpdateForm, setShouldUpdateForm] = useState(false);
  // const appointmentId = useMemo(() => appointmentId, [appointmentId]);
  const [error, setError] = useState(null);

  const setOpen = (boolean) => {
    setFolder({ ...folder, open: boolean });
    setOriginalFolder({ ...originalFolder, open: boolean });
  };

  const setLoaded = (boolean) => {
    setFolder({ ...folder, isLoaded: boolean });
  };

  const getAppointment = (appointmentId) => {
    try {
      let data = events.filter((e) => e.appointmentId === appointmentId)
        setOriginalFolder({
          ...folder,
          isModified: false,
          isAppointmentModified: false,
          AppointmentsFolder: { ...data },
          isLoaded: true,
          isAppointmentValid: true,
        });
        setFolder({
          ...folder,
          isModified: false,
          isAppointmentModified: false,
          AppointmentsFolder: { ...data },
          isLoaded: true,
          isAppointmentValid: true,
        });
    } catch (error) {
      setError(error.message);
      setLoaded(true);
    }
  };

  const revertFolder = () => {
        setFolder({ ...originalFolder });
  };

  const changeFolder = (key, value, isValid) => {
    switch (key) {
      case "todos":
        let isTodosModified = compareValueToOriginalFolder({
          jobTodos: [...value],
        });
        setFolder({
          ...folder,
          isTodosModified: isTodosModified,
          AppointmentsFolder: {
            ...folder.AppointmentsFolder,
            jobTodos: [...value],
          },
        });
        break;
      case "assignments":
        setFolder({
          ...folder,
          AppointmentsFolder: {
            ...folder.AppointmentsFolder,
            jobAssignments: [...value],
          },
        });
        break;
      case "job": {
        console.log("JOB MODIFY MESSAGES");
        let isAppointmentModified = compareValueToOriginalFolder(value);
        console.log("FOLDER MODIFY MESSAGES");
        setFolder({
          ...folder,
          isAppointmentModified: isAppointmentModified,
          isAppointmentValid: isValid ?? folder.isAppointmentValid,
          AppointmentsFolder: {
            ...folder.AppointmentsFolder,
            ...value,
          },
        });
      }
      case "import":
        {
          let isAppointmentModified = true;
          console.log("FOLDER MODIFY MESSAGES");
          setFolder({
            ...folder,
            isAppointmentModified: isAppointmentModified,
            isAppointmentValid: isValid ?? folder.isAppointmentValid,
            AppointmentsFolder: {
              ...folder.AppointmentsFolder,
              ...value,
            },
          });
        }
        break;
    }
  };

  const compareValueToOriginalFolder = (value) => {
    let isAppointmentModified = false;
    let changesArray = [];
    for (const key in value) {
      if (Object.hasOwnProperty.call(value, key)) {
        const element = value[key];
        const originalElement = originalFolder.AppointmentsFolder[key];
        if (element === originalElement) {
          isAppointmentModified = false;
        } else if (element !== originalElement) {
          switch (true) {
            case typeof originalElement === "object" &&
              typeof element === "object":
              if (!deepEqual(originalElement, element)) {
                isAppointmentModified = !deepEqual(originalElement, element);
                changesArray.push({
                  [key]: element,
                  original: originalElement,
                });
              }
              break;
            case typeof element === "array":
              {
                let changes = element.filter((object1) => {
                  return !originalElement.some((object2) => {
                    return object1.id === object2.id;
                  });
                });
                isAppointmentModified = changes.length > 0;
                if (isAppointmentModified) {
                  console.log("change is in array", changes);
                  changesArray.push({
                    [key]: element,
                    original: originalElement,
                  });
                }
              }
              break;
            case (typeof element !== "object" && typeof element !== "array") ||
              typeof element !== typeof originalElement:
              // might need to be more comparisons here
              if (originalElement === null && element !== null) {
                isAppointmentModified = true;
              } else if (
                typeof originalElement === "string" &&
                originalElement.localeCompare(element) !== 0
              ) {
                isAppointmentModified = true;
              } else if (typeof originalElement === "number") {
                if (Number(element) !== originalElement) {
                  isAppointmentModified = true;
                }
              } else {
                isAppointmentModified = true;
              }
              if (isAppointmentModified) {
                changesArray.push({
                  [key]: element,
                  original: originalElement,
                });
                console.log(`change on ${key}`, element);
              }
          }
        }
      }
    }
    console.log("isAppointmentModified should be true, reasons: ", changesArray);
    return changesArray.length !== 0;
  };

  const getAppointmentsFolder = (appointmentId) => {
    try {
      Promise.resolve(getAppointment(appointmentId));
      setLoaded(true);
    } catch (error) {
      setError(error.message);
      setLoaded(true);
    }
  };

  const updateAppointmentsFolder = (closeAfterUpdate = false) => {
    const update = { ...folder.AppointmentsFolder };
    if (update.year === "") {
      update.year = null;
    }
    return API.putData("Appointment", update)
      .then((data) => {
        setFolder({
          ...folder,
          isModified: false,
          isAppointmentModified: false,
          AppointmentsFolder: { ...data },
          isAppointmentValid: true,
          open: !closeAfterUpdate,
        });
        setOriginalFolder({
          ...folder,
          isModified: false,
          isAppointmentModified: false,
          AppointmentsFolder: { ...data },
          isAppointmentValid: true,
          open: !closeAfterUpdate,
        });
        // publishItemIsDirty(
        //   client,
        //   selectedLocation,
        //   "appointment",
        //   data.appointmentId,
        //   "update"
        // );
        window.dispatchEvent(
          new CustomEvent("appointmentUpdate", {
            detail: {
              appointmentId: data.appointmentId,
            },
          })
        );
        return { success: true };
      })
      .catch((e) => {
        return { success: false, error: e };
      });
  };

  const initContext = (appointmentId) => {
    if (appointmentId !== "new") {
      getAppointmentsFolder(appointmentId);
    } else {
      setFolder(initialState);
    }
  };

  const selectedAppointmentId = sessionStorage.getItem("active-appointment");

  useEffect(() => {
    let appointmentId = selectedAppointmentId;
    if (folder.open && appointmentId) {
      initContext(appointmentId);
    } else {
      initContext("new");
    }
    return () => {
      sessionStorage.setItem("active-appointment", "new");
      setOpen(false);
    };
  }, [folder.open]);

  useEffect(() => {
    if (
      folder.isAppointmentModified ||
      folder.isTodosModified ||
      folder.isAssignmentsModified
    ) {
      setFolder({
        ...folder,
        isModified: true,
      });
    } else {
      setFolder({
        ...folder,
        isModified: false,
      });
    }
  }, [
    folder.isAppointmentModified,
    folder.isTodosModified,
    folder.isAssignmentsModified,
  ]);

  return (
    <AppointmentsFolderContext.Provider
      value={{
        ...folder,
        originalFolder,
        setOpen,
        setLoaded,
        error,
        initContext,
        getAppointmentsFolder,
        updateAppointmentsFolder,
        setFolder,
        revertFolder,
        changeFolder,
        shouldUpdateForm,
        setShouldUpdateForm,
      }}
    >
      {children}
    </AppointmentsFolderContext.Provider>
  );
};

AppointmentsFolderProvider.propTypes = {
  appointmentId: PropTypes.string,
  children: PropTypes.node,
};

export { AppointmentsFolderProvider, AppointmentsFolderContext };
