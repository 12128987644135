import { useEffect, useState } from "react";
import { Grid, Toolbar, Typography } from "@mui/material";
import FormDialog from "hdc-components/FormDialog";
import useAuth from "hooks/useAuth";
import { isMobile } from "react-device-detect";
import AppointmentsFolder from "views/pages/appointments-page/AppointmentFolder";

const AppointmentsModal = ({
  open,
  onClose,
  onOpen,
  onAdded,
  appointmentDetails
}) => {
  const [headerColor, setHeaderColor] = useState("#0e0e0e");
  const { selectedLocation } = useAuth();
  const title = appointmentDetails.id ? "Update Appointment" : "Create Appointment";

  useEffect(() => {
    setHeaderColor(appointmentDetails.headerColor);
  }, [appointmentDetails.headerColor])

  return (
    <FormDialog
      open={open}
      handleClose={onClose}
      title={title}
      headerStyle={{
        m: 0,
        p: 0,
        borderTop: `6px solid ${headerColor}`,
      }}
      titleContent={
        <>
          {!isMobile && (
            <Toolbar
              variant="dense"
              component={Grid}
              container
              alignContent="center"
            >
              <Typography
                variant="h3"
                component={Grid}
                item
                xs={12}
                md
                alignItems="center"
              >
                {title}
              </Typography>
            </Toolbar>
          )}
        </>
      }
      content={
                  <AppointmentsFolder
                    onClose={onClose}
                    setOpen={onOpen}
                    onAdded={onAdded}
                    setHeaderColor={setHeaderColor}
                    appointmentDetails={appointmentDetails}
                  />
                
      }
    />
  );
};

export default AppointmentsModal;
