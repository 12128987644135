import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';

export default function FormDialog({open, handleClose, title, content, actions, headerStyle, titleContent}) {
    const theme = useTheme();

    return (
      <Dialog
        open={open}
        aria-labelledby={`form-dialog${title}`}
        {...theme}
        scroll="paper" 
        maxWidth={'lg'}
        fullWidth={true}
        fullScreen={isMobile}
        sx={{
          boxShadow:
            "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
          borderRadius: "8px",    
        }}
      >
        {open && (
          <>
            <DialogTitle
              component={Grid}
              container
              id={`form-dialog${title}`}
              sx={headerStyle || {}}
            >
              {titleContent}
            </DialogTitle>
            <DialogContent sx={{mt:1, px:0, pb:0}}>
              {content}
            </DialogContent>
            {!!actions &&
            <DialogActions sx={{ pr: 2.5 }}>{actions}</DialogActions>
            }
          </>
        )}
      </Dialog>
    );
}
