
const menuDialogButtons = [
  {
    description: "Add Media",
    dialogKey: "media",
    readOnly: false,
  },
  {
    description: "Add Note",
    dialogKey: "note",
    readOnly: false,
  },
  {
    description: "Send Text",
    dialogKey: "message",
    readOnly: false,
  },
  {
    description: "Print Hanger",
    dialogKey: "hanger",
    readOnly: true,
  },
];
const folderRouteButtons = [
  {
    description: "Job Folder",
    tab: 0,
    readOnly: true,
  },
  {
    description: "Media",
    tab: 4,
    readOnly: true,
  },
  {
    description: "Archive",
    tab: 5,
    readOnly: false,
  },
];

const mobileFolderRouteButtons = [
  {
    description: "Job Folder",
    tab: 0,
    readOnly: true,
  },
  {
    description: "Media",
    tab: 2,
    readOnly: false,
  },
  {
    description: "Archive",
    tab: 3,
    readOnly: false,
  },
];

export {
  menuDialogButtons, folderRouteButtons, mobileFolderRouteButtons
}