import { Grid, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

// project imports
import { ThemeProvider } from "@mui/system";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { SquareRounded } from "@mui/icons-material";

const JobClassChip = ({ jobClass, customTheme, disabled, sx = {}, variant, ...others }) => {
    const theme = useTheme(customTheme);

    let defaultSX = {
        color: theme.palette.mode === 'dark' ? theme.palette[jobClass.name].light : theme.palette[jobClass.name].main,
        // ':hover': {
        //     color: theme.palette[jobClass.name].light,
        // }
    };

    return (
      <ThemeProvider theme={theme}>
        <Grid container alignItems={"center"} justifyContent="start" p={0.5}>
          <Grid item xs={2}>
            <SquareRounded sx={defaultSX} />
          </Grid>
          <Grid item xs={10}>
            <Typography noWrap variant="caption"> {jobClass.name} </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
};

JobClassChip.propTypes = {
    sx: PropTypes.object,
    chipcolor: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool
};

export default JobClassChip;