import {
  TextField,
  IconButton,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  Button,
  FormControl,
  Select,
  Chip,
  MenuItem,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import ColorizeIcon from "@mui/icons-material/Colorize";
import ColorPicker from "hdc-components/ColorPicker";
import ConfirmDialog from "hdc-components/ConfirmDialog";
import useSettings from "hooks/useSettings";
import { useStateValue } from "store";

const noassignee = "No Assignee";
const noAssigneeId = "00000000-0000-0000-0000-000000000000";

const AppointmentTypeRow = ({ data, columns }) => {
  const { setUISettingData, defaultSettingGuid } = useSettings();
  const [{ userLocation }] = useStateValue()
  const [categories, setRows] = useState();
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDeleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState();
  const [isValidList, setIsValidList] = useState([]);
  const [isRowAdded, setIsRowAdded] = useState();
  const [focusNewRow, setFocusNewRow] = useState([]);
  const assigneeList = [
    ...userLocation?.assignees
      .filter((a) => a.isActive)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((a) => ({ name: a.name, contactId: a.contactId })),
  ];
    const [selectedAssignees, setSelectedAssignees] = useState([]);

  useEffect(() => {
    setRows(structuredClone(data));
    setIsValidList(
      [...data].map((row) => {
        return row.description.trim() != "";
      })
    );
    data.forEach((row) => {
      if (
        row.apptCategoryId.startsWith(defaultSettingGuid) &&
        focusNewRow.indexOf(row.apptCategoryId) < 0
      ) {
        setIsRowAdded(true);
        setFocusNewRow([...focusNewRow, row.apptCategoryId]);
      }
    });
  }, [data]);

  const setValid = (index) => {
    isValidList[index] = [...categories][index].description.trim() != "";
    setIsValidList([...isValidList]);
    setIsRowAdded(false);
  };

  const onClickColor = (event, index) => {
    setSelectedIndex(index);
    setSelectedRow(categories[index]);
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleColorChange = (color, event) => {
    const selectedColor = color.hex;
    handleChange(selectedIndex, "displayColor", selectedColor);
    setSelectedIndex(-1);
    setMenuPosition(null);
  };

   const handleAssigneeChange = (index, selectedIds) => {
     const rows = structuredClone(data);
     rows[index].assignments = selectedIds.map((id) => ({
       contactId: id,
       apptCategoryId: rows[index].apptCategoryId, // Assuming this is needed
       doNotify: true, // Default to true for now
     }));
    //  debugger
     setUISettingData(rows); // Update the state with new assignments
   };

  const handleChange = (index, targetName, targetValue) => {
    const rows = structuredClone(categories);
    rows[index][targetName] = targetValue;
    setUISettingData(rows);
  };

  const handleCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const handleClickDelete = (index) => {
    setDeleteRowIndex(index);
    setDeleteConfirm(true);
  };

  const handleDelete = () => {
    setDeleteRowIndex("");
    setDeleteConfirm(false);
    setUISettingData(
      structuredClone(categories).filter(
        (row, index) => index !== deleteRowIndex
      )
    );
    setIsRowAdded(false);
  };

  return (
    <>
      {categories?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell width={columns[0].width} sx={{ textAlign: "center" }}>
              <Tooltip title="Modify Color" placement="top">
                <span>
                  <IconButton
                    aria-label="color"
                    size="small"
                    style={{
                      backgroundColor: row.displayColor,
                      color: "white",
                    }}
                    onClick={(e) => onClickColor(e, index)}
                  >
                    <ColorizeIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>

            <TableCell width={columns[1].width}>
              <TextField
                fullWidth
                autoFocus={isRowAdded}
                error={
                  !isValidList[index] &&
                  (isRowAdded ? categories?.length != index + 1 : true)
                }
                id="standard-required"
                label="Category"
                value={row.description}
                name="description"
                onChange={(e) => {
                  row.description = e.target.value;
                  handleChange(index, e.target.name, row.description);
                }}
                variant="standard"
                autoComplete="off"
                onBlur={(e) => {
                  setValid(index);
                }}
              />
            </TableCell>
            <TableCell width={columns[2]?.width} sx={{ textAlign: "center" }}>
              <FormControl fullWidth>
                <Select
                  multiple
                  value={row.assignments.map((a) => a.contactId)} // Use contact IDs as selected values
                  onChange={(event) =>
                    handleAssigneeChange(index, event.target.value)
                  }
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <Typography>No Assignees Selected</Typography>; // Display when no assignees are selected
                    }

                    const chipsToDisplay = selected.slice(0, 2); // Display only the first 3 chips
                    const additionalCount = selected.length - 2; // Calculate how many more

                    return (
                      <div>
                        {chipsToDisplay.map((id) => (
                          <Chip
                            key={id}
                            label={
                              assigneeList.find((a) => a.contactId === id)?.name
                            }
                          />
                        ))}
                        {additionalCount > 0 && (
                          <Chip label={`+${additionalCount} more`} />
                        )}
                      </div>
                    );
                  }}
                >
                  {assigneeList.map((assignee) => (
                    <MenuItem
                      key={assignee.contactId}
                      value={assignee.contactId}
                    >
                      {assignee.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>

            <TableCell width={columns[3].width} sx={{ textAlign: "center" }}>
              <Switch
                disabled={row.isPublic}
                checked={row.isActive}
                onChange={(e) =>
                  handleChange(index, e.target.name, e.target.checked)
                }
                name="isActive"
              />
            </TableCell>

            <TableCell width={columns[4].width} sx={{ textAlign: "center" }}>
              <Tooltip title="Delete Note Category" placement="top">
                <span>
                  <IconButton
                    disabled={row.isPublic}
                    aria-label="delete"
                    size="small"
                    onClick={() => handleClickDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      <ColorPicker
        selected={selectedRow?.color}
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        handleClick={handleColorChange}
      />
      <ConfirmDialog
        open={openDeleteConfirm}
        onYes={handleDelete}
        onNo={handleCloseDeleteConfirm}
        contentSx={{ maxWidth: "360px" }}
        disableBackdropClick={true}
      >
        <Typography variant="span">
          Are you sure you want to delete this category?
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default AppointmentTypeRow;
