import {
  Autocomplete,
  Card,
  CardHeader,
  CardContent,  
  Grid,
  IconButton,  
  TextField,
  Tooltip,
} from "@mui/material";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import DoneIcon from '@mui/icons-material/Done';
import CachedIcon from '@mui/icons-material/Cached';
import InputMask from "react-input-mask";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import { useStateValue } from "store";
import { compareIfChanged } from "helpers";
import { phoneRegex } from "helpers/regex";
import TransitionBlocker from "../../blocker";

const urlRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const initialSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
  address1: yup.string().trim().required("Address is required"),
  city: yup.string().trim().required("City is required"),
  phone: yup.string()
    .matches(phoneRegex, "Phone is not valid")
    .required("Phone is required"),
	websiteUrl: yup.string()
    .matches(urlRegExp, "URL must be valid"),
});

const usSchema = yup.object({
  postalCode: yup.string().trim()
    .required("Zip is required")
    .min(5, 'Zip must be 5 characters or more'),
  subdivision: yup.string()
    .nullable()
    .required("State is required")
});

const canadaSchema =  yup.object({
  postalCode: yup.string().trim()
    .required("Postal code is required")
    .min(6, 'Postal code must be 6 characters or more'),
  subdivision: yup.string()
    .nullable()
    .required("Province is required")
});

const isCanada = (country) => {
  if(!country)
    return false;
  return country.toLowerCase() === 'canada'
};

const InformationSettings = () => {
  const [{ snackbar }, dispatch] = useStateValue();
  const { selectedLocation, user, renameLocation } = useAuth();
  const [ originalData, setOriginalData ] = useState({});
  const [ locationData, setLocationData ] = useState({});
  const [ subdivisionOptions, setSubdivisionOptions ] = useState([]);
  const [ initReady, setInitReady ] = useState(false);
  const [ forceToRender, setForceToRender ] = useState(0);
  const formRef = useRef(null);

  let isSupportRole = user.roles.indexOf("support") > -1;

  useEffect(() => {
    const locationPromise = API.getData("locations", "settings");
    Promise.all([locationPromise]).then((responses) => {      
      const locationResult = structuredClone(responses[0]);
      resetLocationData(locationResult);
      let country = locationResult?.country;
      if (!country) {
        country = 'US';
      }
      API.getData("locations", `country/${country}`).then((response) => {
        updateSubdivisionOptions(response);            
        setInitReady(true);
      })
      .catch(error => {
        setInitReady(true);
      });
    });
  }, [selectedLocation]);

  useEffect(() => {
    if(locationData?.name != "") {
      formRef.current.setValues(structuredClone(locationData));
    }
  }, [locationData]);

  const validationSchema = initialSchema.concat(
    isCanada(locationData?.country)
    ? canadaSchema
    : usSchema
  );

  const resetLocationData = (locationData) => {
    setOriginalData(locationData);
    setLocationData(structuredClone(locationData));
  };

  const generateKey = () => {
    API.postData("locations", null, "generateApiKey").then((response) => {
      originalData.apiKey = response.apiKey;
      formRef.current.values.apiKey = response.apiKey;
      setForceToRender(forceToRender + 1);
    });
  };

  const updateSubdivisionOptions = (data) => {
    console.log(data);
    setSubdivisionOptions(
      data?.states
        .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
        .map(s => {
          return {
            id: s.subdivisionId,
            value: s.subdivisionId,
            label: s.name
          };
        })
      ?? []
    );
  };

  const handleSave = (callback) => {
    const param = structuredClone(formRef.current.values);
    API.putData("locations", param, "settings").then((response) => {
      if(response) {
        resetLocationData(structuredClone(response));
        renameLocation(response.name);
      }
      if(typeof(callback) == "function") callback();
    });
  };

  const affectOnChange = () => {
    setTimeout(() => setForceToRender(forceToRender + 1), 1);
  };
  
  const copyToClipboard = (e) => {
    if(!e.target.value)
      return;

    navigator.clipboard.writeText(e.target.value);
    dispatch({
      type: 'OPEN_SNACKBAR',
      payload: {
          open: true,
          message: 'API key copied to clipboard!',
          variant: 'alert',
          alert: {
              color: 'success'
          },
          close: false
      }
    });
  };

  const hasChanges = () => {
    return formRef.current == null
      ? false
      : compareIfChanged(structuredClone(originalData), structuredClone(formRef.current.values));
  };

  const enableSave = () => {
    if(formRef.current == null)
      return false;

    return formRef.current.isValid && hasChanges();
  };

  return (
    <Box display="flex" justifyContent="center">
      <Formik
        innerRef={formRef}
        initialValues={locationData}
        validationSchema={validationSchema}
        validateOnChange={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form height="100%" width="100%">
            {!!initReady && (
              <Grid container maxWidth={"800px"}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title="Information"
                      sx={{
                        px: 3,
                        py: 2,
                        backgroundColor: "rgb(33, 33, 33, 0.2)",
                      }}
                      action={
                        <Tooltip title="Save" placement="top">
                          <span>
                            <IconButton
                              onClick={handleSave}
                              disabled={!enableSave()}
                            >
                              <DoneIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    />

                    <CardContent
                      sx={{
                        maxHeight: "calc(100vh - 300px)",
                        overflow: "auto",
                        backgroundColor: "rgb(33, 33, 33, 0.5)",
                        "&:last-child": {
                          paddingBottom: "24px",
                        },
                      }}
                    >
                      <Grid container px={1} spacing={0}>
                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <TextField
                              name="name"
                              value={values.name || ""}
                              label="Name"
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              inputProps={{ maxLength: 120 }}
                              onChange={(e) => {
                                handleChange(e);
                                values.name = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                affectOnChange();
                              }}
                              error={Boolean(errors.name)}
                              helperText={errors.name || " "}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <TextField
                              name="address1"
                              value={values.address1 || ""}
                              label="Address"
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              inputProps={{ maxLength: 120 }}
                              onChange={(e) => {
                                handleChange(e);
                                values.address1 = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.address1)}
                              helperText={errors.address1 || " "}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <TextField
                              name="city"
                              value={values.city || ""}
                              label="City"
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              inputProps={{ maxLength: 120 }}
                              onChange={(e) => {
                                handleChange(e);
                                values.city = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.city)}
                              helperText={errors.city || " "}
                            />
                          </Grid>
                          <Grid item xs={4} pl={1}>
                            <Autocomplete
                              disableClearable
                              size="small"
                              name="subdivision"
                              value={values.subdivision}
                              options={subdivisionOptions}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  variant="standard"
                                  helperText={errors.subdivision || " "}
                                  error={Boolean(errors.subdivision)}
                                  sx={{
                                    pt: "3px",
                                    "& .MuiFormLabel-root": {
                                      top: "4px",
                                    },
                                  }}
                                  {...params}
                                  label={
                                    isCanada(values.country)
                                      ? "Province"
                                      : "State"
                                  }
                                />
                              )}
                              onChange={(e, selected) => {
                                setFieldValue("subdivision", selected.label);
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              renderOption={(props, option) => {
                                return (
                                  <li
                                    {...props}
                                    value={option.label}
                                    key={option.id}
                                    component="div"
                                  >
                                    {option.label}
                                  </li>
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} pl={1}>
                            <TextField
                              name="postalCode"
                              value={values.postalCode || ""}
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              label={
                                isCanada(values.country) ? "Postal Code" : "Zip"
                              }
                              inputProps={{ maxLength: 10 }}
                              onChange={(e) => {
                                handleChange(e);
                                values.postalCode = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.postalCode)}
                              helperText={errors.postalCode || " "}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <InputMask
                              name="phone"
                              value={values.phone || ""}
                              autoComplete="off"
                              mask="(999) 999-9999"
                              type="text"
                              onChange={(e) => {
                                handleChange(e);
                                values.phone = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                affectOnChange();
                              }}
                              error={Boolean(errors.phone)}
                              helperText={errors.phone || " "}
                            >
                              {(props) => (
                                <TextField
                                  {...props}
                                  variant="standard"
                                  fullWidth={true}
                                  label="Phone"
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={8} pl={1}>
                            <TextField
                              name="websiteUrl"
                              value={values.websiteUrl || ""}
                              label="Website"
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              inputProps={{ maxLength: 120 }}
                              onChange={(e) => {
                                handleChange(e);
                                values.websiteUrl = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.websiteUrl && errors.websiteUrl
                              )}
                              helperText={
                                (errors.websiteUrl &&
                                  touched.websiteUrl &&
                                  errors.websiteUrl) ||
                                " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12} sx={{ pb: 3 }}>
                          <Grid item xs={12}>
                            <TextField
                              name="alias"
                              value={values.alias || ""}
                              label="Alias"
                              fullWidth={true}
                              autoComplete="off"
                              variant="standard"
                              id="settings-alias"
                              inputProps={{ maxLength: 120 }}
                              onChange={(e) => {
                                handleChange(e);
                                values.alias = e.target.value;
                                affectOnChange();
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={11}>
                            <TextField
                              fullWidth={true}
                              value={values.apiKey || ""}
                              inputProps={{ readOnly: true }}
                              autoComplete="off"
                              variant="standard"
                              id="settings-notify-cycle"
                              label="Auto Uploader API Key"
                              helperText={
                                values.apiKey
                                  ? "Click to copy to clipboard"
                                  : "No key generated yet"
                              }
                              onClick={copyToClipboard}
                              onFocus={(e) => e.target.select()}
                            />
                          </Grid>
                          <Grid item xs={1} pl={1}>
                            <Tooltip
                              title={
                                <div>
                                  Regenerate API Key?
                                  <br />
                                  Client using the existing key must be updated
                                </div>
                              }
                              placement="top"
                            >
                              <IconButton
                                size="large"
                                color="success"
                                disabled={!isSupportRole}
                                onClick={generateKey}
                              >
                                <CachedIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>

      <TransitionBlocker
        openPrompt={hasChanges()}
        onYes={handleSave}
        disableYes={!enableSave()}
      />
    </Box>
  );
};

export default InformationSettings;