// material-ui
import { FormControl, Grid, MenuItem, Select, Skeleton } from "@mui/material";
import { reportTypes } from "const";
import { useEffect, useState } from "react";
import API from "services/Api";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import ReportOptions from "../dashboard-page/dashboard-toolbar/ReportOptions";
import NewReportDialog from "../dashboard-page/NewReportDialog";
import useReportsData from "hooks/useReportsData";
import CustomerInfo from "./reports/customer-info";
import DeliveredByRo from "./reports/delivered-ro";
import InboundVehicle from "./reports/inbound-vehicle";
import OutboundVehicle from "./reports/outbound-vehicle";
import InventoryReport from "./reports/inventory-report/index.js";
import ForecastReport from "./reports/forecast-report";
import ReportContainer from "./report-card";
import AssigneeLeaderboard from "./reports/assignee-leaderboard";
import SalesReport from "./reports/sales-report";
import MSOReportCard from "./reports/report-card";

const reportControl = (setType) => {
  switch (setType) {
    case reportTypes.assigneeLeaderboard:
      return <AssigneeLeaderboard />;
    case reportTypes.customerInfo:
      return <CustomerInfo />;
      case reportTypes.deliveredRo:
        return <DeliveredByRo />;
    case reportTypes.forecastReport:
      return <ForecastReport />;
    case reportTypes.inboundVehicle:
      return <InboundVehicle />;
      case reportTypes.inventory:
        return <InventoryReport />;
    case reportTypes.outboundVehicle:
      return <OutboundVehicle />;
      case reportTypes.salesReport:
        return <SalesReport/>
    case reportTypes.reportCard:
      return <MSOReportCard/>

    default:
      return null;
  }
};

const ReportsView = () => {
  const {
    templates,
    savedReports,
    saveReportsByType,
    addReport,
    updateReports,
    isReport,
  } = useReportsData();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [activeReportId, setActiveReportId] = useState(null);
  const [activeReport, setActiveReport] = useState(null);
  const [editReport, setEditReport] = useState(null);

  const filteredPinnedReports = savedReports?.filter((r) =>
    isReport(r.setType)
  );

  const handleEdit = (report) => {
    setEditReport(report);
    setIsOpen(true);
  };

  const handleUpdate = (report) => {
    setActiveReport(report);
    setActiveReportId(report.reportOptionId);
  };

  const handleSubmit = (report) => {
    setIsOpen(false);
    if (editReport) {
      setEditReport(null);
      updateReports(report);
      setActiveReportId(report.reportOptionId);
      setActiveReport(report);
    } else {
      addReport(report);
      setActiveReportId(report.reportOptionId);
      setActiveReport(report);
    }
  };

  const handleDelete = () => {
    setActiveReport(null);
    setActiveReportId(null);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setEditReport(null);
  };

  const getReportTemplates = () => {
    API.getData("reports", "templates").then((json) => {
      saveReportsByType(json, "templates");
    });
  }

  const getSavedReports = () => {
    API.getData("reports", "saved").then((json) => {
      saveReportsByType(json, "saved");
    });
  }

   const fetchAllData = async () => {
     const fetchPromises = [
       getReportTemplates(),
       getSavedReports()
     ];

     try {
       await Promise.all(fetchPromises);
       setLoading(false);
     } catch (error) {
       console.error("Error fetching data:", error);
       setLoading(false);
     }
   };

  useEffect(() => {
    fetchAllData()
   
  }, []);

   

  useEffect(() => {
    if (!activeReportId && filteredPinnedReports.length > 0) {
      setActiveReportId(filteredPinnedReports[0].reportOptionId);
    }
  }, [filteredPinnedReports]);

  useEffect(() => {
    const activeReport = savedReports?.find(
      (r) => r.reportOptionId === activeReportId
    );
    if (activeReport) {
      setActiveReport(activeReport);
    } else {
      setActiveReport(null)
    }
  }, [activeReportId]);

  return (
    <PerfectScrollbar>
      <Grid container component="main" columnSpacing={2} px={2}>
        <Grid item xs={6}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            {isLoading && <Skeleton height="38px"/>
            }
            {!isLoading &&
            <Select
              labelId="report-id-label"
              name="reportOptionId"
              fullWidth
              value={activeReportId}
              onChange={(e) => {setActiveReportId(e.target.value); setActiveReport(null)}}
            >
              {!!filteredPinnedReports &&
                filteredPinnedReports.map((t, i) => (
                  <MenuItem key={i} value={t.reportOptionId}>
                    {t.name}
                  </MenuItem>
                ))}
            </Select>
}
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6} xl={6}>
          <ReportOptions
            title="Report"
            savedReports={savedReports}
            onNewReport={() => setIsOpen(true)}
            onUpdateSavedReports={updateReports}
          />
        </Grid>
        {!!activeReport && templates && activeReport?.reportOptionId && (
          <Grid item xs={12}>
            <ReportContainer
              report={activeReport}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
              onEdit={handleEdit}
            >
              {reportControl(activeReport?.setType)}
            </ReportContainer>
          </Grid>
        )}
      </Grid>
      <NewReportDialog
        title="Report"
        open={isOpen}
        editValues={editReport}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </PerfectScrollbar>
  );
};

export default ReportsView;
