import { useEffect, useState } from "react";
import API from "services/Api";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import SettingsCard from "../../SettingsCard";
import AppointmentTypeRow from "./AppointmentTypeRow";

const newRow = {
  apptCategoryId: "00000000-0000-0000-0000-000000000000",
  description: "",
  displayColor: "#000000",
  isActive: true,
  assignments: [
  ],
};

const AppointmentTypesSettings = () => {
  const [initReady, setInitReady] = useState(false);
  const { selectedLocation } = useAuth();
  const {
    uiSettingData,
    setOriginSettingData,
    setUISettingData,
    hasChangesSettingData,
    defaultSettingGuid,
  } = useSettings();

  useEffect(() => {
    setInitReady(true);
    setUISettingData([]);
  }, []);

  useEffect(() => {
    API.getData("Appointment", "ApptCategory").then((result) => {
      const cats = result.filter((r) => !r.deletedAt);
      setOriginSettingData(structuredClone(cats));
      setUISettingData(structuredClone(cats));
    });
  }, [selectedLocation]);

  const resetIfNewItem = (items) => {
    items.forEach((item) => {
      if (item.apptCategoryId.startsWith(defaultSettingGuid)) {
        item.apptCategoryId = defaultSettingGuid;
      }
      if (item.assignments && item.assignments.length > 0) {
        item.assignments.forEach((assignment) => {
          if (assignment.apptCategoryId.startsWith(defaultSettingGuid)) {
            assignment.apptCategoryId = defaultSettingGuid; // Adjust this as needed, if resetting is necessary
          }
        });
      }
    });
    
    return items;
  };

  const save = (callback) => {
    API.putData("Appointment",
      resetIfNewItem(uiSettingData),
      "ApptCategory",
    ).then((result) => {
      if (result) {
        const cats = result.filter((r) => !r.deletedAt);
        setOriginSettingData(structuredClone(cats));
        setUISettingData(structuredClone(cats));
      }
      if (typeof callback == "function") callback();
    });
  };

  const addTableRow = () => {
    const row = structuredClone(newRow);
    row.apptCategoryId = row.apptCategoryId + Date.now();
    setUISettingData([...uiSettingData, row]);
  };

  const enableSave = () => {
    const invalid = uiSettingData?.some((d) => d.description.trim() == "");
    return hasChangesSettingData && !invalid;
  };

  const columns = [
    { name: "Color", width: "15%" },
    { name: "Category Description", width: "40%" },
    { name: "Assignments", width: "20%" }, // New column for Assign button
    { name: "Active", width: "15%" },
    { name: "Delete", width: "10%" },
  ];


  return (
    !!initReady && (
      <>
        <SettingsCard
          title="Appointment Category"
          columns={columns}
          addButtonTooltip="Add Appointment Category"
          handleAddRow={addTableRow}
          handleSave={save}
          enableSave={enableSave()}
        >
          <AppointmentTypeRow data={uiSettingData} columns={columns} />
        </SettingsCard>
      </>
    )
  );
};

export default AppointmentTypesSettings;
