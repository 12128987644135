import React, { useCallback, useEffect, useState } from "react";
import Calendar from "ui-component/calendar";
import { Views } from "react-big-calendar";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useTheme } from "@emotion/react";
import useAppointments from "hooks/useAppointments";
import PerfectScrollbar from "react-perfect-scrollbar";
import JobClassCalendar from "../schedule-page/jobclass-schedule/JobClassCalendar";
import { AppointmentsFolderProvider } from "contexts/AppointmentsFolderContext";
import AppointmentsModal from "views/modals/appointment-modal";
import useAppointmentsFolder from "hooks/useAppointmentsFolder";

export default function DesktopAppointments() {
  const { events, fetchAppointments, addEvent, removeEvent, updateEvent, appointmentTypes, date, changeDate } =
    useAppointments();
  const { folder, setFolder } = useAppointmentsFolder();
  console.log("Folder State from Desktop Appointments:", folder);
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    type: "",
    allDay: false,
  });
  const [view, setView] = useState(Views.MONTH);
  const [filteredTypes, setFilteredTypes] = useState(
    appointmentTypes.reduce((acc, type) => ({ ...acc, [type.type]: true }), {})
  );

  const theme = useTheme();

  const onView = useCallback((newView) => setView(newView), [setView]);

  const openFolder = (appointmentId) => {
    sessionStorage.setItem("active-appointment-id", appointmentId);
    setFolder({
      ...folder,
      appointmentId: appointmentId,
      open: true,
    });
    setOpen(true);
  };

  const closeFolder = () => {
    setOpen(false);
    sessionStorage.setItem("active-appointment-id", "new");
    setFolder({
      ...folder,
      appointmentId: "new",
      open: false,
    });
  };

  const createBlankAppointment = () => ({
    apptCategoryId: "",
    startDateTime: new Date(),
    endDateTime: moment().add(1, "hour").toDate(),
    allDay: false,
    clientReminder: false,
    description: "",
    clientName: "",
    clientCellular: "",
    clientEmail: "",
    year: null,
    make: "",
    model: "",
    color: "",
    notes: "",
  });

  // Inside DesktopAppointments component
  const handleSelectSlot = ({ start, end }) => {
    const newAppointment = {
      apptCategoryId: "",
      startDateTime: moment(new Date(start)),
      endDateTime: moment(new Date(end)),
      allDay: false,
      clientReminder: false,
      description: "",
      clientName: "",
      clientCellular: "",
      clientEmail: "",
      year: null,
      make: "",
      model: "",
      color: "",
      notes: "",
    };

    setNewEvent(newAppointment);
    setFolder({
      appointmentId: "new",
      AppointmentsFolder: newAppointment,
      open: true,
      isAppointmentModified: false,
    });
    setOpen(true); // Open the folder/modal
  };

  const handleSelectEvent = (event) => {
    const selectedAppointment = {
      id: event.id,
      apptCategoryId: event.apptCategoryId || "",
      startDateTime: event.start,
      endDateTime: event.end,
      allDay: event.allDay || false,
      clientReminder: event.clientReminder || false,
      description: event.title || "",
      clientName: event.clientName || "",
      clientCellular: event.clientCellular || "",
      clientEmail: event.clientEmail || "",
      year: event.year || null,
      make: event.make || "",
      model: event.model || "",
      color: event.vehicleColor || "",
      notes: event.notes || "",
      headerColor: event.color
    };

    setNewEvent(selectedAppointment);
    setFolder({
      appointmentId: event.id,
      AppointmentsFolder: selectedAppointment,
      open: true,
      isAppointmentModified: false,
    });
    setOpen(true); // Open the folder/modal
  };

  const handleEventAddOrUpdate = () => {
    const formattedEvent = {
      id: newEvent.id || Date.now(),
      title: newEvent.title,
      start: new Date(newEvent.start),
      end: new Date(newEvent.end),
      allDay: newEvent.allDay,
      type: newEvent.type,
      color: appointmentTypes.find((type) => type.type === newEvent.type)
        ?.color,
    };

    if (newEvent.id) {
      updateEvent(formattedEvent);
    } else {
      addEvent(formattedEvent);
    }
    // openFolder(false);
    resetNewEvent();
  };

  const handleEventDelete = () => {
    removeEvent(newEvent.id);
    // openFolder(false);
    setNewEvent({
      id: null,
      title: "",
      start: "",
      end: "",
      type: "",
      allDay: false,
    });
  };

  const handleDateChange = (date) => {
    changeDate(new Date(date));
  };

  const eventPropGetter = (event) => {
    const appointmentType = appointmentTypes.find(
      (type) => type.type === event.type
    );
    const backgroundColor = appointmentType ? appointmentType.color : "#3174ad";
    return { style: { backgroundColor } };
  };

  const handleFilterChange = (type) => {
    setFilteredTypes({ ...filteredTypes, [type]: !filteredTypes[type] });
  };

  useEffect(() => {
    if (appointmentTypes.length > 0) {
      const initialFilters = appointmentTypes.reduce(
        (acc, type) => ({ ...acc, [type.apptCategoryId]: true }),
        {}
      );
      setFilteredTypes(initialFilters);
      console.log("Initial Filters:", initialFilters);
    }
  }, [appointmentTypes]);

  useEffect(() => {
    sessionStorage.setItem("active-appointment-id", "new");
  }, []);

  useEffect(() => {
    const appointmentDirtyEvent = (e) => {
      fetchAppointments()
      console.log("appointment dirty event from appointments view");
    };
    window.addEventListener("fetchAppointments", appointmentDirtyEvent);
    return () => {
      window.removeEventListener("fetchAppointments", appointmentDirtyEvent);
    };
  }, []);

  return (
    <PerfectScrollbar>
      <Box>
        <Grid
          container
          style={{
            width: "100vw",
            height: "calc(100vh - 118px)",
          }}
          wrap="nowrap"
        >
          <Grid item xs={4} sm pr={1} mt={1}>
            <Grid
              item
              xs={12}
              className="job-class-calendar"
              px={1}
              sx={{
                bgcolor: theme.palette.background.paper,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <JobClassCalendar
                  label={"View Appointments"}
                  value={date}
                  setValue={handleDateChange}
                />
              </LocalizationProvider>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: theme.palette.background.paper,
                  color: "#fff",
                  borderRadius: 1,
                  mt: 1,
                }}
              >
                <Box px={1}>
                  <Grid container>
                    <Grid item xs={11} alignContent={"center"}>
                      <Typography variant="h6">Appointment Types</Typography>
                    </Grid>
                  </Grid>
                  {appointmentTypes &&
                    filteredTypes &&
                    appointmentTypes.map((type) => (
                      <FormControlLabel
                        key={type.apptCategoryId}
                        control={
                          <Checkbox
                            checked={!!filteredTypes[type.apptCategoryId]}
                            onChange={() =>
                              handleFilterChange(type.apptCategoryId)
                            }
                            sx={{
                              color: type.color,
                              "&.Mui-checked": {
                                color: type.color,
                              },
                            }}
                          />
                        }
                        label={type.type}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                    ))}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={8} lg={11}>
            <Box
              sx={{
                width: "100%",
                pt: 1.5,
              }}
            >
              <Calendar
                events={events.filter((event) => filteredTypes[event.apptCategoryId])}
                // events={events}
                startAccessor="start"
                endAccessor="end"
                selectable
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                style={{ height: "calc(100vh - 118px)" }}
                view={view}
                views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                onView={onView}
                date={date}
                onNavigate={handleDateChange}
                eventPropGetter={eventPropGetter}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AppointmentsFolderProvider>
        <AppointmentsModal
          appointmentDetails={newEvent}
          open={open}
          onClose={closeFolder}
          onAdded={handleEventAddOrUpdate}
        />
      </AppointmentsFolderProvider>
    </PerfectScrollbar>
  );
}
