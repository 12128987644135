import React, { useState } from "react";
import { Menu, MenuItem, Tooltip, IconButton } from "@mui/material";
import { GroupAddRounded } from "@mui/icons-material";
import useAssignees from "hooks/useAssignees";

const AssignAllMenu = ({ onAssignAll }) => {
  const { locationAssignees } = useAssignees();
  const selectableAssignees = locationAssignees
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .filter((a) => a.isActive)
    .map((a) => {
      return {name: a.name, contactId: a.contactId}
    });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (value) => {
    onAssignAll(value);
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Click and select an assignee to automatically assign as the default assignee for all departments.">
        <IconButton variant="outlined" onClick={handleClick}>
          <GroupAddRounded />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          key={"none"}
          onClick={() => handleOptionSelect("No Assignee")}
        >
          No Assignee
        </MenuItem>
        {selectableAssignees.map((option) => (
          <MenuItem
            key={option.contactId}
            onClick={() => handleOptionSelect(option.name)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AssignAllMenu;
