import { Grid, Typography } from "@mui/material";

const ReportRow = ({title, value, pb=0.5}) => {
  return (
    <Grid container item xs={12} pb={pb}>
      <Grid item xs={6}>
        <Typography variant="h3">{title}:</Typography>
      </Grid>
      <Grid item xs={6} justifyContent={"end"}>
        <Typography variant="h3" textAlign={"right"}>{value}</Typography>
      </Grid>
    </Grid>
  );
}

export default ReportRow;